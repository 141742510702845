<template>
  <div class="add-worker">
    <div class="add-worker__row">
      <div class="add-worker__label">
        Наименование должности 
      </div>
      <el-input v-model="name" />
    </div>

    <div v-if="!editData" class="add-worker__row">
      <div class="add-worker__label">
        Описание рабочего места <span class="required-field">*</span>
      </div>
      <el-input v-model="description" :rows="3" type="textarea" />
    </div>
    <div v-else>
      <div v-for="(item, idx) in arrWorkerPosNames" :key="idx" class="add-worker__row">
        <div class="add-worker__label">
          Описание рабочего места {{ idx + 1 }} <span class="required-field">*</span>
        </div>
        <el-input v-model="arrWorkerPosNames[idx]" :rows="3" type="textarea" />
      </div>
    </div>

    <div v-if="!editData" class="add-worker__row">
      <div class="add-worker__label">
        Вероятность присутствия на рабочем месте
      </div>
      <el-input type="number" v-model="chance"/>
    </div>
    <div v-else>
      <div v-for="(item, idx) in arrProbabilityOfPresenceWorker" :key="idx" class="add-worker__row">
        <div class="add-worker__label">
          Вероятность присутствия на рабочем месте {{ idx + 1 }}
        </div>
        <el-input v-model="arrProbabilityOfPresenceWorker[idx]"/>
      </div>
    </div>
    
    <div class="add-worker__buttons">
      <ButtonElement
        text="Отмена"
        @click="cancel"
      />
      <ButtonElement
        v-if="!editData"
        text="Добавить рабочее место"
        :disabled="!name || !chance"
        @click="addWorkPlace"
      />
      <ButtonElement
        v-if="!editData"
        text="Создать работника"
        :disabled="!name || !chance"
        @click="saveWorker"
      />
      <ButtonElement
        v-else
        text="Сохранить"
        :disabled="!name"
        @click="editWorker"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from '../elements/ButtonElement'
import { AlertNotify } from '@/mixin/alert_notify'

export default {
  name: 'AddWorker',
  mixins: [AlertNotify],
  components: {
    ButtonElement,
  },
  data() {
    return {
      name: '',
      description: '',
      chance: 1,
    }
  },
  created() {
    if(this.editData) {
      for(let key in this.editData) {
        this[key] = this.editData[key]
      }
    }
  },
  mounted() {
    if(this.$store.state.Project.currentWorkerPost)this.name = this.$store.state.Project.currentWorkerPost
  },
  computed: {
    editData() {
      return this.$store.state.Project.currentEditObject
    }
  },
  
  methods: {
    saveWorker() {
      if(this.name && this.chance) {
        const arrPointWorker = this.$store.state.Project.currentWorkerPosition
        const arrPointWorkers = []
        for(let key in arrPointWorker){
          arrPointWorkers.push(arrPointWorker[key])
        }

        const arrProbabilityOfPresenceWorker = this.$store.state.Project.arrProbabilityOfPresenceWorker
        arrProbabilityOfPresenceWorker.push(this.chance)
        const arrWorkerPosNames = this.$store.state.Project.arrWorkerPosNames
        arrWorkerPosNames.push(this.description)
        this.$store.commit('Project/addNewWorker', {
          id: Date.now(),
          name: this.name,
          arrProbabilityOfPresenceWorker,
          arrWorkerPosNames,
          arrPosWorker: arrPointWorkers
        })
        this.$store.commit('Project/setData', { label: 'workerStop', data: false })
        this.$store.commit('Project/addDrawPoints', { data: arrPointWorker, catName: 'Worker' })
        this.$store.commit('Project/setData', { label: 'arrProbabilityOfPresenceWorker', data: [] })
        this.$store.commit('Project/setData', { label: 'arrWorkerPosNames', data: [] })
        this.$store.commit('Project/setData', { label: 'currentWorkerPost', data: '' })
        this.$store.commit('Project/setData', { label: 'selectedSwitcherName', data: 'Работники' })
        this.$store.commit('Project/setData', { label: 'selectedSwitcher', data: !this.$store.state.Project.selectedSwitcher })
        this.$store.dispatch('Dialog/closeDialog')
      } else {
        this.errorNotify('Заполните обязательные поля')
      }
    },
    editWorker() {
      if(this.name) {
        this.$store.commit('Project/editWorker', {
          id: this.id,
          name: this.name,
          arrProbabilityOfPresenceWorker: this.arrProbabilityOfPresenceWorker,
          arrWorkerPosNames: this.arrWorkerPosNames
        })
        this.$store.dispatch('Dialog/closeDialog')
      } else {
        this.errorNotify('Заполните обязательные поля')
      }
    },
    addWorkPlace() {
      if(this.name && this.chance) {
        this.$store.commit('Project/setData', { label: 'workerStop', data: false })
        this.$store.commit('Project/setData', { label: 'currentWorkerPost', data: this.name })
        this.$store.commit('Project/pushData', { label: 'arrProbabilityOfPresenceWorker', data: this.chance })
        this.$store.commit('Project/pushData', { label: 'arrWorkerPosNames', data: this.description })
        this.$store.dispatch('Dialog/closeDialog')
      } else {
        this.errorNotify('Заполните обязательные поля')
      }
    },
    cancel() {
      this.$store.commit('Project/setData', { label: 'currentEditObject', data: null })
      this.$store.dispatch('Dialog/closeDialog')
      this.$store.commit('Project/setData', { label: 'arrProbabilityOfPresenceWorker', data: [] })
      this.$store.commit('Project/setData', { label: 'arrWorkerPosNames', data: [] })
      this.$store.commit('Project/setData', { label: 'currentWorkerPost', data: '' })
      this.$store.commit('Dialog/setData', { label: 'redrawCanvasAfterCloseDialog', data: true })
      this.$store.commit('Project/setData', { label: 'workerStop', data: false })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

.add-worker {
  &__row {
    border-bottom: 1px solid $lightGrey;
    padding: 2rem 3rem;
  }

  &__label {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $basicBlack;
    margin-bottom: 0.8rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    padding: 4rem 0;
  }
}
</style>
