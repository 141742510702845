<template>
  <component
    :is="link ? 'router-link' : 'button'"
    :to="link"
    class="button"
    :class="viewButton"
    :disabled="disabled"
  >
    {{ text }}
  </component>
</template>

<script>
export default {
  name: 'ButtonElement',
  props: {
    text: {
      type: String,
      default: null
    },
    link: {
      type: String,
      default: null
    },
    fill: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    viewButton() {
      let str = ''
      if (this.fill) str = str + ' button--' + this.fill
      if (this.color) str = str + ' button--' + this.color
      if (this.disabled) str = str + ' disabled'
      return str
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

.button {
  border: 1px solid $blue;
  background: $blue;
  color: $basicWhite;
  border-radius: 1rem;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 5rem;
  height: 5rem;
  padding: 0 2rem;
  display: block;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &+.button {
    margin-left: 2rem;
  }

  &--empty {
    background: transparent;
    color: $blue;
  }

  &--white {
    border: 1px solid $basicWhite;
    background: $basicWhite;
    color: $blue;

    &.button--empty {
      background: transparent;
      color: $basicWhite;
      transition: .3s;

      &:hover {
        background: $brightOrange;
      }
    }
  }
}
</style>
