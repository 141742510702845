<template>
  <div class="set-distance">
    <div class="set-distance__title">
      Введите расстояние между точками, м:
    </div>
    <el-input v-model="distance" />
    <div class="set-distance__actions">
      <ButtonElement
        text="Отмена"
        @click="$store.dispatch('Dialog/closeDialog')"
      />
      <ButtonElement text="Применить" />
    </div>
  </div>
</template>

<script>
import ButtonElement from '@/components/elements/ButtonElement'

export default {
  name: 'SetDistance',
  components: {
    ButtonElement,
  },
  data() {
    return {
      distance: null,
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

.set-distance {
  &__title {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 2rem;
    color: $basicBlack;
  }

  &__actions {
    display: flex;
    margin-top: 2.2rem;
  }
}
</style>
