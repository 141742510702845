import { createApp } from 'vue'

import { dragscrollNext } from 'vue-dragscroll'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import './assets/styles/style.scss'

import router from './router'
import store from './store'

import { Chart, registerables } from 'chart.js'

import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables,ChartJsPluginDataLabels)

import AuthLayout from './layouts/AuthLayout'
import AdminLayout from './layouts/AdminLayout'
import UserLayout from './layouts/UserLayout'

import App from './App.vue'

createApp(App)
  .directive('dragscroll', dragscrollNext)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .component('AuthLayout', AuthLayout)
  .component('AdminLayout', AdminLayout)
  .component('UserLayout', UserLayout)
  .mount('#app')
