<template>
  <div v-if="accidents.length" class="accidents">
    <div class="accidents__tabs">
      <div class="accidents__tabs-header">
        <div class="accidents__tabs-header-items">
          <div
            v-for="item in accidents"
            :key="item.name"
            class="accidents__tabs-header-item"
            :class="{ active : activeTab === item.name }"
            @click="activeTab = item.name"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="accidents__tabs-header-add" @click="addNewAccident">
          <img src="../assets/icons/icon-add.svg" alt="">
        </div>
      </div>
      <div class="accidents__tabs-content">
        <div class="accidents__row">
          <div class="accidents__row-label">
            Характеристика аварии:
          </div>
          <div class="accidents__row-input">
            <el-select v-model="selectedAccident.failure" :disabled="pipeDepressurization.length ? true : false">
              <el-option label="Локальное повреждение" value='hole' />
              <el-option label="Полное разрушение" value='destruction' />
            </el-select>
          </div>
        </div>
        <div v-if="selectedAccident.failure === 'hole'" class="accidents__row">
          <div class="accidents__row-label">
            Диаметр отверстия, мм:
          </div>
          <div class="accidents__row-input">
            <el-input-number
              v-model="selectedAccident.diameterHole"
              controls-position="right"
              :min="1"
              :disabled="pipeDepressurization.length ? true : false"
            />
          </div>
        </div>
        <div v-if="selectedAccident.failure === 'tankFire'" class="accidents__row">
          <div class="accidents__row-label">
            Диаметр вертикального резервуара, м:
          </div>
          <div class="accidents__row-input">
            <el-input-number
              v-model="selectedAccident.diameterTank"
              controls-position="right"
              :min="1"
            />
          </div>
        </div>
        <div class="accidents__actions">
          <ButtonElement text="Рассчитать параметры аварии" @click="calculateFailure" />
        </div>
        <div class="accidents__results">
          <div v-if="selectedAccident.massSubstanceRate && selectedAccident.massSubstanceRate != 0" class="accidents__results-item">
            <div class="accidents__results-name">
              Расход вещества через отверстие, кг/c:
            </div>
            <div class="accidents__results-value">
              {{ selectedAccident.massSubstanceRate }}
            </div>
          </div>
          <div v-if="selectedAccident.actualSpillArea !== undefined && selectedAccident.actualSpillArea !== null" class="accidents__results-item">
            <div class="accidents__results-name">
              Фактическая площадь пролива, м<sup>2</sup>
            </div>
            <div class="accidents__results-value">
              {{ selectedAccident.actualSpillArea }}
            </div>
          </div>
          <div v-if="selectedAccident.massSubstanceInCloud && selectedAccident.massSubstanceInCloud != 0" class="accidents__results-item">
            <div class="accidents__results-name">
              Масса газа или пара в облаке, кг:
            </div>
            <div class="accidents__results-value">
              {{ selectedAccident.massSubstanceInCloud }}
            </div>
          </div>
        </div>
        <div class="accidents__probability">
          <div v-if="selectedAccident.instantaneousIgnitionProbability && selectedAccident.instantaneousIgnitionProbability != 0" class="accidents__results-item">
            <div class="accidents__results-name">
              Вероятность мгновенного воспламенения:
            </div>
            <div class="accidents__results-value">
              {{ selectedAccident.instantaneousIgnitionProbability }}
            </div>
          </div>
          <div v-if="selectedAccident.absenceSubsequentIgnitionProbability && selectedAccident.absenceSubsequentIgnitionProbability != 0" class="accidents__results-item">
            <div class="accidents__results-name">
              Вероятность отсутствия последующего воспламенения:
            </div>
            <div class="accidents__results-value">
              {{ selectedAccident.absenceSubsequentIgnitionProbability }}
            </div>
          </div>
          <div v-if="selectedAccident.absenceCalmProbability && selectedAccident.absenceCalmProbability != 0" class="accidents__results-item">
            <div class="accidents__results-name">
              Вероятность отсутствия штиля:
            </div>
            <div class="accidents__results-value">
              {{ selectedAccident.absenceCalmProbability }}
            </div>
          </div>
          <div v-if="selectedAccident.absenceCalmProbability && selectedAccident.absenceCalmProbability != 0" class="accidents__results-item">
            <div class="accidents__results-name">
              Вероятность взрыва облака:
            </div>
            <div class="accidents__results-value">
              {{ selectedAccident.cloudExplosionProbability }}
            </div>
          </div>
        </div>
        <div v-if="selectedAccident.canUseTree" class="accidents__row">
          <div>
            <div class="accidents__row-label">
              Число стадий в дереве событий:
            </div>
            <div class="accidents__row-actions">
              <button @click="addNewTree">
                Добавить дерево
              </button>
              <button @click="calculateEventTrees">
                Рассчитать деревья
              </button>
              <button @click="selectedAccident.trees.length = 0">
                Удалить деревья
              </button>
            </div>
          </div>
          <div class="accidents__row-input">
            <el-input-number
              v-model="treesSteps"
              controls-position="right"
              :min="1"
              :max="10"
            />
          </div>
        </div>
        <div class="accidents__trees">
          <div
            v-for="tree in selectedAccident.trees"
            :key="tree.id"
            class="accidents__tree"
          >
            <div
              v-for="(row, rowIdx) in tree.rows"
              :key="'row' + rowIdx"
              class="accidents__tree-row"
            >
              <div
                v-for="(col, idx) in row.cols"
                :key="'col' + idx"
                class="accidents__tree-col"
                :class="{ empty: col.empty, result: col.hasOwnProperty('result') }"
              >
                <div v-if="rowIdx === 0 && idx === 0">
                  <input type="text" v-model="col.value" class="accidents__tree-col-input">
                </div>
                <div v-else>
                  <el-input-number 
                    v-if="!col.empty && col.hasOwnProperty('value')" 
                    v-model="col.value" 
                    :max="col.maxValue ? 1 : Infinity"
                    :min="0"
                    :controls="false"
                  />
                </div>
                <span v-if="!col.empty && col.hasOwnProperty('result')">
                  {{ calculateTreeBranchResult(tree.rows[rowIdx - 1], tree.rows[rowIdx]) }}
                </span>
              </div>
              <div class="accidents__tree-select">
                <el-select v-model="row.fireVariant">
                  <el-option
                    v-for="item in fireVariants"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
              <div class="accidents__tree-result">
                {{row.result}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from './elements/ButtonElement'
import { evaluate } from 'mathjs'
import * as html2canvas from "html2canvas";

export default {
  name: 'AccidentsPipe',
  components: {
    ButtonElement,
  },
  props: {
    equipmentClass: {
      type: String,
      default: ''
    },
    stateOfAggregation: {
      type: String,
      default: ''
    },
    equipment: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      evaluate,
      GRAVITY: 9.807,
      ATMOSPHERE_PRESSURE: 101325,
      UNIVERSAL_GAS_CONST: 8314.472,
      AIR_THERMAL_CONDUCTIVITY: 2.6e-2,
      AIR_FLOW_VELOCITY: 1,
      AIR_KINEMATIC_VISCOSITY: 1.6e-5,
      activeTab: '1',
      treesSteps: 1,
      fireVariants: [
        {
          label: 'Нет пожара',
          value: 0,
        },
        {
          label: 'Пожар пролива',
          value: 1,
        },
        {
          label: 'Огненный шар',
          value: 2,
        },
        {
          label: 'Пожар-вспышка',
          value: 3,
        },
        {
          label: 'Горизонтальный факел',
          value: 4,
        },
        {
          label: 'Вертикальный факел',
          value: 5,
        },
        {
          label: 'Взрыв облака',
          value: 6,
        },
        {
          label: 'Взрыв резервуара',
          value: 7,
        },
      ],
      characteristicsOfAccident: [
        {
          label: 'Локальное повреждение',
          value: 'local'
        },
        {
          label: 'Полное разрушение',
          value: 'full'
        },
      ],
      accidents: [
        {
          title: 'Авария 1',
          name: '1',
          characteristic: '',
          value: 0,
          failure: 'hole',
          diameterHole: null,
          diameterTank: null,
          massSubstanceInEquipment: null,
          massSubstanceRate: null,
          actualSpillArea: null,
          massSubstanceInCloud: null,
          instantaneousIgnitionProbability: null,
          subsequentIgnitionProbability: null,
          cloudExplosionProbability: null,
          absenceSubsequentIgnitionProbability: null,
          absenceCalmProbability: null,
          trees: [],
          canUseTree: false
        },
      ],
    }
  },
  created() {
    if(this.editData && this.editData.objDetailData && this.editData.objDetailData.accidents){
      this.accidents = this.editData.objDetailData.accidents
      this.$store.commit('Accidents/setData', { label: 'failureParameterObj', data: this.editData.failureParameterObj })
    }
    if(this.pipeDepressurization.length){
      this.accidents = []
      this.pipeDepressurization.map((element, index) => {
         this.accidents.push(
            {
              title: `Авария ${index + 1}`,
              name: `${index + 1}`,
              characteristic: '',
              value: 0,
              failure: 'hole',
              diameterHole: +element.diameter,
              diameterTank: null,
              massSubstanceInEquipment: null,
              massSubstanceRate: null,
              actualSpillArea: null,
              massSubstanceInCloud: null,
              instantaneousIgnitionProbability: null,
              subsequentIgnitionProbability: null,
              cloudExplosionProbability: null,
              absenceSubsequentIgnitionProbability: null,
              absenceCalmProbability: null,
              trees: [],
              canUseTree: false,
              calculateFrequency: element.frequency
            }
         )
      })
    }
  },
  unmounted() {
    this.$store.commit('Project/setData', { label: 'pipeDepressurization', data: [] })
  },
  watch: {
    accidents: {
      deep: true,
      handler() {
        this.$store.commit('Project/setData', { label: 'accidents', data: this.accidents })
      },
    },
  },
  computed: {
    selectedAccident() {
      return this.accidents.find(el => el.name === this.activeTab)
    },
    substance() {
      return this.$store.state.Project.substance
    },
    region() {
      return this.$store.state.Project.region
    },
    diameterHole() {
      return this.selectedAccident.diameterHole / 1000
    },
    diameterTank() {
      return this.selectedAccident.diameterTank
    },
    pipeDepressurizationData() {
      return this.$store.state.Project.pipeDepressurizationData
    },
    pipeDepressurization() {
      return this.$store.state.Project.pipeDepressurization
    },
    editData() {
        return this.$store.state.Project.currentEditObject
    }
  },
  methods: {
    calculateFailure() {
      let failureParameter = {}
      if (this.equipmentClass === 'pipelineConnectedTank' && this.stateOfAggregation === 'liquid' && this.selectedAccident.failure === 'tankFire') {
        failureParameter = this.calculateTankFire()
      }
      if (this.equipmentClass === 'pipelineConnectedTank' && this.stateOfAggregation === 'liquid' && this.selectedAccident.failure === 'hole') {
        failureParameter = this.calculatePipeConnectedTankLiquidHole()
      }
      if (this.equipmentClass === 'pipelineConnectedTank' && this.stateOfAggregation === 'liquid' && this.selectedAccident.failure === 'destruction') {
        failureParameter = this.calculatePipeConnectedTankLiquidDestruction()
      }
      if (this.equipmentClass === 'pipelineConnectedPump' && this.stateOfAggregation === 'liquid' && this.selectedAccident.failure === 'hole') {
        failureParameter = this.calculatePipeConnectedPumpLiquidHole()
      }
      if (this.equipmentClass === 'pipelineConnectedPump' && this.stateOfAggregation === 'liquid' && this.selectedAccident.failure === 'destruction') {
        failureParameter = this.calculatePipeConnectedPumpLiquidDestruction()
      }
      if (this.equipmentClass === 'pipelineConnectedTank' && this.stateOfAggregation === 'compressedGas' && this.selectedAccident.failure === 'hole') {
        failureParameter = this.calculatePipeConnectedTankCompressedGasHole()
      }
      if (this.equipmentClass === 'pipelineConnectedTank' && this.stateOfAggregation === 'compressedGas' && this.selectedAccident.failure === 'destruction') {
        failureParameter = this.calculatePipeConnectedTankCompressedGasDestruction()
      }
      if (this.equipmentClass === 'pipelineConnectedCompressor' && this.stateOfAggregation === 'compressedGas' && this.selectedAccident.failure === 'hole') {
        failureParameter = this.calculatePipeConnectedCompressorCompressedGasHole()
      }
      if (this.equipmentClass === 'pipelineConnectedCompressor' && this.stateOfAggregation === 'compressedGas' && this.selectedAccident.failure === 'destruction') {
        failureParameter = this.calculatePipeConnectedCompressorCompressedGasDestruction()
      }
      if (this.equipmentClass === 'pipelineConnectedTank' && this.stateOfAggregation === 'liquefiedGasFluid' && this.selectedAccident.failure === 'hole') {
        failureParameter = this.calculatePipeConnectedTankFluidPhaseLiquefiedGasHole()
      }
      if (this.equipmentClass === 'pipelineConnectedTank' && this.stateOfAggregation === 'liquefiedGasFluid' && this.selectedAccident.failure === 'destruction') {
        failureParameter = this.calculatePipeConnectedTankFluidPhaseLiquefiedGasDestruction()
      }
      if (this.equipmentClass === 'pipelineConnectedPump' && this.stateOfAggregation === 'liquefiedGasFluid' && this.selectedAccident.failure === 'hole') {
        failureParameter = this.calculatePipeConnectedPumpFluidPhaseLiquefiedGasHole()
      }
      if (this.equipmentClass === 'pipelineConnectedPump' && this.stateOfAggregation === 'liquefiedGasFluid' && this.selectedAccident.failure === 'destruction') {
        failureParameter = this.calculatePipeConnectedPumpFluidPhaseLiquefiedGasDestruction()
      }
      if (this.equipmentClass === 'pipelineConnectedTank' && this.stateOfAggregation === 'liquefiedGasVapor' && this.selectedAccident.failure === 'hole') {
        failureParameter = this.calculatePipeConnectedTankVaporPhaseLiquefiedGasHole()
      }
      if (this.equipmentClass === 'pipelineConnectedTank' && this.stateOfAggregation === 'liquefiedGasVapor' && this.selectedAccident.failure === 'destruction') {
        failureParameter = this.calculatePipeConnectedTankVaporPhaseLiquefiedGasDestruction()
      }
      if (this.equipmentClass === 'pipelineConnectedCompressor' && this.stateOfAggregation === 'liquefiedGasVapor' && this.selectedAccident.failure === 'hole') {
        failureParameter = this.calculatePipeConnectedCompressorVaporPhaseLiquefiedGasHole()
      }
      if (this.equipmentClass === 'pipelineConnectedCompressor' && this.stateOfAggregation === 'liquefiedGasVapor' && this.selectedAccident.failure === 'destruction') {
        failureParameter = this.calculatePipeConnectedCompressorVaporPhaseLiquefiedGasDestruction()
      }
      this.selectedAccident.massSubstanceInEquipment = failureParameter.massSubstanceInEquipment.toFixed(1)
      this.selectedAccident.massSubstanceRate = failureParameter.massSubstanceRate.toFixed(3)
      this.selectedAccident.actualSpillArea = failureParameter.actualSpillArea.toFixed(1)
      this.selectedAccident.massSubstanceInCloud = failureParameter.massSubstanceInCloud.toFixed(1)

      const currentAccidentIndex = this.accidents.findIndex(el => el.name === this.activeTab)
      this.$store.commit('Accidents/setParameters', { index: currentAccidentIndex, parameters: { failureParameter }, label: 'failureParameterObj' })

      this.selectedAccident.canUseTree = true

      if (this.stateOfAggregation === 'compressedGas' && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate > 0 && failureParameter.massSubstanceRate < 1) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.005
        this.selectedAccident.subsequentIgnitionProbability = 0.005
        this.selectedAccident.cloudExplosionProbability = 0.08
      }
      if (this.stateOfAggregation === 'compressedGas' && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate >= 1 && failureParameter.massSubstanceRate <= 50) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.035
        this.selectedAccident.subsequentIgnitionProbability = 0.036
        this.selectedAccident.cloudExplosionProbability = 0.24
      }
      if (this.stateOfAggregation === 'compressedGas' && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate > 50) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.150
        this.selectedAccident.subsequentIgnitionProbability = 0.176
        this.selectedAccident.cloudExplosionProbability = 0.6
      }
      if (this.stateOfAggregation === 'compressedGas' && this.selectedAccident.failure === 'destruction') {
        this.selectedAccident.instantaneousIgnitionProbability = 0.2
        this.selectedAccident.subsequentIgnitionProbability = 0.24
        this.selectedAccident.cloudExplosionProbability = 0.6
      }

      if (this.stateOfAggregation === 'liquefiedGasFluid' && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate > 0 && failureParameter.massSubstanceRate < 1) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.005
        this.selectedAccident.subsequentIgnitionProbability = 0.005
        this.selectedAccident.cloudExplosionProbability = 0.08
      }
      if (this.stateOfAggregation === 'liquefiedGasFluid' && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate >= 1 && failureParameter.massSubstanceRate <= 50) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.035
        this.selectedAccident.subsequentIgnitionProbability = 0.036
        this.selectedAccident.cloudExplosionProbability = 0.24
      }
      if (this.stateOfAggregation === 'liquefiedGasFluid' && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate > 50) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.150
        this.selectedAccident.subsequentIgnitionProbability = 0.176
        this.selectedAccident.cloudExplosionProbability = 0.6
      }
      if (this.stateOfAggregation === 'liquefiedGasFluid' && this.selectedAccident.failure === 'destruction') {
        this.selectedAccident.instantaneousIgnitionProbability = 0.2
        this.selectedAccident.subsequentIgnitionProbability = 0.24
        this.selectedAccident.cloudExplosionProbability = 0.6
      }

      if (this.stateOfAggregation === 'liquefiedGasVapor' && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate > 0 && failureParameter.massSubstanceRate < 1) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.005
        this.selectedAccident.subsequentIgnitionProbability = 0.005
        this.selectedAccident.cloudExplosionProbability = 0.08
      }
      if (this.stateOfAggregation === 'liquefiedGasVapor' && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate >= 1 && failureParameter.massSubstanceRate <= 50) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.035
        this.selectedAccident.subsequentIgnitionProbability = 0.036
        this.selectedAccident.cloudExplosionProbability = 0.24
      }
      if (this.stateOfAggregation === 'liquefiedGasVapor' && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate > 50) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.150
        this.selectedAccident.subsequentIgnitionProbability = 0.176
        this.selectedAccident.cloudExplosionProbability = 0.6
      }
      if (this.stateOfAggregation === 'liquefiedGasVapor' && this.selectedAccident.failure === 'destruction') {
        this.selectedAccident.instantaneousIgnitionProbability = 0.2
        this.selectedAccident.subsequentIgnitionProbability = 0.24
        this.selectedAccident.cloudExplosionProbability = 0.6
      }
      if (this.stateOfAggregation === 'liquid' && this.substance.flashPoint < 301 && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate > 0 && failureParameter.massSubstanceRate < 1) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.005
        this.selectedAccident.subsequentIgnitionProbability = 0.005
        this.selectedAccident.cloudExplosionProbability = 0.08
      }
      if (this.stateOfAggregation === 'liquid' && this.substance.flashPoint < 301 && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate >= 1 && failureParameter.massSubstanceRate <= 50) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.035
        this.selectedAccident.subsequentIgnitionProbability = 0.036
        this.selectedAccident.cloudExplosionProbability = 0.24
      }
      if (this.stateOfAggregation === 'liquid' && this.substance.flashPoint < 301 && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate > 50) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.150
        this.selectedAccident.subsequentIgnitionProbability = 0.176
        this.selectedAccident.cloudExplosionProbability = 0.6
      }
      if (this.stateOfAggregation === 'liquid' && this.substance.flashPoint < 301 && this.selectedAccident.failure === 'destruction') {
        this.selectedAccident.instantaneousIgnitionProbability = 0.2
        this.selectedAccident.subsequentIgnitionProbability = 0.24
        this.selectedAccident.cloudExplosionProbability = 0.6
      }

      if (this.stateOfAggregation === 'liquid' && this.substance.flashPoint >= 301 && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate > 0 && failureParameter.massSubstanceRate < 1) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.005
        this.selectedAccident.subsequentIgnitionProbability = 0.005
        this.selectedAccident.cloudExplosionProbability = 0.05
      }
      if (this.stateOfAggregation === 'liquid' && this.substance.flashPoint >= 301 && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate >= 1 && failureParameter.massSubstanceRate <= 50) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.015
        this.selectedAccident.subsequentIgnitionProbability = 0.015
        this.selectedAccident.cloudExplosionProbability = 0.05
      }
      if (this.stateOfAggregation === 'liquid' && this.substance.flashPoint >= 301 && this.selectedAccident.failure === 'hole' && failureParameter.massSubstanceRate > 50) {
        this.selectedAccident.instantaneousIgnitionProbability = 0.04
        this.selectedAccident.subsequentIgnitionProbability = 0.042
        this.selectedAccident.cloudExplosionProbability = 0.05
      }
      if (this.stateOfAggregation === 'liquid' && this.substance.flashPoint >= 301 && this.selectedAccident.failure === 'destruction') {
        this.selectedAccident.instantaneousIgnitionProbability = 0.05
        this.selectedAccident.subsequentIgnitionProbability = 0.061
        this.selectedAccident.cloudExplosionProbability = 0.1
      }
      if (this.selectedAccident.failure !== 'tankFire' && failureParameter.massSubstanceInCloud !== 0) {
        this.selectedAccident.instantaneousIgnitionProbability = Number(this.selectedAccident.instantaneousIgnitionProbability).toFixed(3)
        this.selectedAccident.absenceSubsequentIgnitionProbability = (1 - this.selectedAccident.subsequentIgnitionProbability).toFixed(3)
        this.selectedAccident.absenceCalmProbability = (1 - this.region.calm).toFixed(3)
        this.selectedAccident.cloudExplosionProbability = Number(this.selectedAccident.cloudExplosionProbability).toFixed(3)
      } else if (failureParameter.massSubstanceInCloud === 0) {
        this.selectedAccident.instantaneousIgnitionProbability = Number(this.selectedAccident.instantaneousIgnitionProbability).toFixed(3)
        this.selectedAccident.absenceSubsequentIgnitionProbability = (1 - this.selectedAccident.subsequentIgnitionProbability).toFixed(3)
      }
    },
    calculateTankFire() {
      let massSubstanceInEquipment = 0;
      let massSubstanceRate = 0;
      let actualSpillArea = (Math.PI * Math.pow(this.diameterTank, 2)) / 4;
      let massSubstanceInCloud = 0;
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },
    calculatePipeConnectedTankLiquidHole() {
      let massSubstanceInEquipment = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let temperature = this.equipment.equipmentLocation === 'underground' ? this.region.averageMaxTemperature : this.region.absoluteMaxTemperature

      let palletAreaFact

      if (this.equipment.palletAvailability) {
        palletAreaFact = this.equipment.efficiencyPalletAtHole ? this.equipment.palletArea : Number.MAX_SAFE_INTEGER
      } else {
        palletAreaFact = Number.MAX_SAFE_INTEGER
      }
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4

      let massInSystemBeforeDamage = this.substance.substanceDensity * (this.equipment.fillRatioTank * this.equipment.volumeTank + cutoffPipeVolume)

      let holeArea = (Math.PI * Math.pow(this.diameterHole, 2)) / 4

      let massSubstanceRate = 0.6 * holeArea * this.substance.substanceDensity * Math.sqrt(2 * this.GRAVITY * this.equipment.deltaLiquidHeight + 2 * this.equipment.overpressureTank / this.substance.substanceDensity)

      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER

      let massFact = Math.min(massInSystemBeforeDamage, massSubstanceRate * factTimeLiquidationHole, massSubstanceRate * this.equipment.cutoffTime + this.substance.substanceDensity * cutoffPipeVolume)

      let freeSpillArea = this.equipment.surfaceSpreadCoefficient * massFact / this.substance.substanceDensity
      let actualSpillArea = Math.min(palletAreaFact, freeSpillArea)
      let saturatedVaporPressure = Math.pow(10, this.substance.constA - (this.substance.constB / (this.substance.constCa + temperature - 273.15)))
      let evaporationRate = 1e-6 * Math.sqrt(this.substance.molarMass) * saturatedVaporPressure
      let normalizedEvaporationTime = massFact <= 20 ? 900 : 3600
      let factTimeLiquidationSpill = this.equipment.liquidationSpillEmergencyServices ? this.equipment.timeLiquidationSpill : Number.MAX_SAFE_INTEGER
      let theoreticalEvaporationTime = Math.min(normalizedEvaporationTime, factTimeLiquidationSpill)
      let theoreticalEvaporationMass = evaporationRate * actualSpillArea * theoreticalEvaporationTime
      let massSubstanceInCloud = theoreticalEvaporationMass <= massFact ? theoreticalEvaporationMass : massFact
      massSubstanceInCloud = temperature >= this.substance.flashPoint ? massSubstanceInCloud : 0
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },

    calculatePipeConnectedTankLiquidDestruction() {
      let massSubstanceInEquipment = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let temperature = this.equipment.equipmentLocation === 'underground' ? this.region.averageMaxTemperature : this.region.absoluteMaxTemperature
      let palletAreaFact
      if (this.equipment.palletAvailability) {
        palletAreaFact = this.equipment.efficiencyPalletAtDestruction ? this.equipment.palletArea : Number.MAX_SAFE_INTEGER
      } else {
        palletAreaFact = Number.MAX_SAFE_INTEGER
      }
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4
      let massInSystemBeforeDamage = this.substance.substanceDensity * (this.equipment.fillRatioTank * this.equipment.volumeTank + cutoffPipeVolume)
      let holeArea = (Math.PI * Math.pow(pipeDiameter / 1000, 2)) / 4
      let massSubstanceRate = 0.6 * holeArea * this.substance.substanceDensity * Math.sqrt(2 * this.GRAVITY * this.equipment.deltaLiquidHeight + 2 * this.equipment.overpressureTank / this.substance.substanceDensity)
      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER
      let massFact = Math.min(massInSystemBeforeDamage, massSubstanceRate * factTimeLiquidationHole, massSubstanceRate * this.equipment.cutoffTime + this.substance.substanceDensity * cutoffPipeVolume)
      let freeSpillArea = this.equipment.surfaceSpreadCoefficient * massFact / this.substance.substanceDensity
      let actualSpillArea = Math.min(palletAreaFact, freeSpillArea)
      let saturatedVaporPressure = Math.pow(10, this.substance.constA - (this.substance.constB / (this.substance.constCa + temperature - 273.15)))
      let evaporationRate = 1e-6 * Math.sqrt(this.substance.molarMass) * saturatedVaporPressure
      let normalizedEvaporationTime = massFact <= 20 ? 900 : 3600
      let factTimeLiquidationSpill = this.equipment.liquidationSpillEmergencyServices ? this.equipment.timeLiquidationSpill : Number.MAX_SAFE_INTEGER
      let theoreticalEvaporationTime = Math.min(normalizedEvaporationTime, factTimeLiquidationSpill)
      let theoreticalEvaporationMass = evaporationRate * actualSpillArea * theoreticalEvaporationTime
      let massSubstanceInCloud = theoreticalEvaporationMass <= massFact ? theoreticalEvaporationMass : massFact
      massSubstanceInCloud = temperature >= this.substance.flashPoint ? massSubstanceInCloud : 0
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },
    calculatePipeConnectedPumpLiquidHole() {
      let massSubstanceInEquipment = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let temperature = this.equipment.equipmentLocation === 'underground' ? this.region.averageMaxTemperature : this.region.absoluteMaxTemperature

      let palletAreaFact

      if (this.equipment.palletAvailability) {
        palletAreaFact = this.equipment.efficiencyPalletAtHole ? this.equipment.palletArea : Number.MAX_SAFE_INTEGER
      } else {
        palletAreaFact = Number.MAX_SAFE_INTEGER
      }

      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4

      let cutoffPipeCrossSectionalArea = (Math.PI * Math.pow(pipeDiameter / 1000, 2)) / 4

      let holeArea = (Math.PI * Math.pow(this.diameterHole, 2)) / 4

      let pumpPressure = this.substance.substanceDensity * this.GRAVITY * this.equipment.pumpHead + this.ATMOSPHERE_PRESSURE

      let massFlowRatePump = this.equipment.pumpVolumeFlowRate * this.substance.substanceDensity

      let massSubstanceRate = holeArea <= 0.2 * cutoffPipeCrossSectionalArea ? 0.6 * holeArea * this.substance.substanceDensity * Math.sqrt(2 * (pumpPressure - this.ATMOSPHERE_PRESSURE) / this.substance.substanceDensity) : massFlowRatePump

      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER

      let massFact = Math.min(massSubstanceRate * factTimeLiquidationHole, massSubstanceRate * this.equipment.cutoffTime + this.substance.substanceDensity * cutoffPipeVolume)

      let freeSpillArea = this.equipment.surfaceSpreadCoefficient * massFact / this.substance.substanceDensity
      
      let actualSpillArea = Math.min(palletAreaFact, freeSpillArea)


      let saturatedVaporPressure = Math.pow(10, this.substance.constA - (this.substance.constB / (this.substance.constCa + temperature - 273.15)))
      let evaporationRate = 1e-6 * Math.sqrt(this.substance.molarMass) * saturatedVaporPressure
      let normalizedEvaporationTime = massFact <= 20 ? 900 : 3600
      let factTimeLiquidationSpill = this.equipment.liquidationSpillEmergencyServices ? this.equipment.timeLiquidationSpill : Number.MAX_SAFE_INTEGER
      let theoreticalEvaporationTime = Math.min(normalizedEvaporationTime, factTimeLiquidationSpill)
      let theoreticalEvaporationMass = evaporationRate * actualSpillArea * theoreticalEvaporationTime
      let massSubstanceInCloud = theoreticalEvaporationMass <= massFact ? theoreticalEvaporationMass : massFact
      massSubstanceInCloud = temperature >= this.substance.flashPoint ? massSubstanceInCloud : 0
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },
    calculatePipeConnectedPumpLiquidDestruction() {
      let massSubstanceInEquipment = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let temperature = this.equipment.equipmentLocation === 'underground' ? this.region.averageMaxTemperature : this.region.absoluteMaxTemperature
      let palletAreaFact
      if (this.equipment.palletAvailability) {
        palletAreaFact = this.equipment.efficiencyPalletAtDestruction ? this.equipment.palletArea : Number.MAX_SAFE_INTEGER
      } else {
        palletAreaFact = Number.MAX_SAFE_INTEGER
      }
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4
      let massSubstanceRate = this.equipment.pumpVolumeFlowRate * this.substance.substanceDensity
      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER;
      let massFact = Math.min(massSubstanceRate * factTimeLiquidationHole, massSubstanceRate * this.equipment.cutoffTime + this.substance.substanceDensity * cutoffPipeVolume)
      let freeSpillArea = this.equipment.surfaceSpreadCoefficient * massFact / this.substance.substanceDensity
      let actualSpillArea = Math.min(palletAreaFact, freeSpillArea)
      let saturatedVaporPressure = Math.pow(10, this.substance.constA - (this.substance.constB / (this.substance.constCa + temperature - 273.15)))
      let evaporationRate = 1e-6 * Math.sqrt(this.substance.molarMass) * saturatedVaporPressure
      let normalizedEvaporationTime = massFact <= 20 ? 900 : 3600
      let factTimeLiquidationSpill = this.equipment.liquidationSpillEmergencyServices ? this.equipment.timeLiquidationSpill : Number.MAX_SAFE_INTEGER
      let theoreticalEvaporationTime = Math.min(normalizedEvaporationTime, factTimeLiquidationSpill)
      let theoreticalEvaporationMass = evaporationRate * actualSpillArea * theoreticalEvaporationTime
      let massSubstanceInCloud = theoreticalEvaporationMass <= massFact ? theoreticalEvaporationMass : massFact
      massSubstanceInCloud = temperature >= this.substance.flashPoint ? massSubstanceInCloud : 0
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },

    calculatePipeConnectedTankCompressedGasHole() {
      let massSubstanceInEquipment = 0
      let actualSpillArea = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4
      let holeArea = (Math.PI * Math.pow(this.diameterHole, 2)) / 4
      let densityGasInEquipment = this.substance.molarMass * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature)
      let firstCondition = 0.8 * holeArea * Math.sqrt(2 * (this.substance.adiabaticExponent / (this.substance.adiabaticExponent - 1)) * this.equipment.operatingPressure * densityGasInEquipment * (Math.pow(this.ATMOSPHERE_PRESSURE / this.equipment.operatingPressure, 2 / this.substance.adiabaticExponent) - Math.pow(this.ATMOSPHERE_PRESSURE / this.equipment.operatingPressure, (this.substance.adiabaticExponent + 1) / this.substance.adiabaticExponent)))
      let secondCondition = 0.8 * holeArea * Math.sqrt(this.equipment.operatingPressure * densityGasInEquipment * this.substance.adiabaticExponent * Math.pow(2 / (this.substance.adiabaticExponent + 1), (this.substance.adiabaticExponent + 1) / (this.substance.adiabaticExponent - 1)))
      let massSubstanceRate = (this.ATMOSPHERE_PRESSURE / this.equipment.operatingPressure) >= Math.pow(2 / (this.substance.adiabaticExponent + 1), this.substance.adiabaticExponent / (this.substance.adiabaticExponent - 1)) ? firstCondition : secondCondition
      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER
      let massSubstanceInCloud = Math.min(this.substance.molarMass * (this.equipment.volumeTank + cutoffPipeVolume) * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature), massSubstanceRate * this.equipment.cutoffTime + this.substance.molarMass * cutoffPipeVolume * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature), massSubstanceRate * factTimeLiquidationHole)
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },
    calculatePipeConnectedTankCompressedGasDestruction() {
      let massSubstanceInEquipment = 0
      let actualSpillArea = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4
      let cutoffPipeCrossSectionalArea = (Math.PI * Math.pow(pipeDiameter / 1000, 2)) / 4
      let densityGasInEquipment = this.substance.molarMass * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature)
      let firstCondition = 0.8 * cutoffPipeCrossSectionalArea * Math.sqrt(2 * (this.substance.adiabaticExponent / (this.substance.adiabaticExponent - 1)) * this.equipment.operatingPressure * densityGasInEquipment * (Math.pow(this.ATMOSPHERE_PRESSURE / this.equipment.operatingPressure, 2 / this.substance.adiabaticExponent) - Math.pow(this.ATMOSPHERE_PRESSURE / this.equipment.operatingPressure, (this.substance.adiabaticExponent + 1) / this.substance.adiabaticExponent)))
      let secondCondition = 0.8 * cutoffPipeCrossSectionalArea * Math.sqrt(this.equipment.operatingPressure * densityGasInEquipment * this.substance.adiabaticExponent * Math.pow(2 / (this.substance.adiabaticExponent + 1), (this.substance.adiabaticExponent + 1) / (this.substance.adiabaticExponent - 1)))
      let massSubstanceRate = (this.ATMOSPHERE_PRESSURE / this.equipment.operatingPressure) >= Math.pow(2 / (this.substance.adiabaticExponent + 1), this.substance.adiabaticExponent / (this.substance.adiabaticExponent - 1)) ? firstCondition : secondCondition;
      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER;
      let massSubstanceInCloud = Math.min(this.substance.molarMass * (this.equipment.volumeTank + cutoffPipeVolume) * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature), massSubstanceRate * this.equipment.cutoffTime + this.substance.molarMass * cutoffPipeVolume * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature), massSubstanceRate * factTimeLiquidationHole)
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },
    calculatePipeConnectedCompressorCompressedGasHole() {
      let massSubstanceInEquipment = 0
      let actualSpillArea = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let cutoffPipeCrossSectionalArea = (Math.PI * Math.pow(pipeDiameter / 1000, 2)) / 4
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4
      let holeArea = (Math.PI * Math.pow(this.diameterHole, 2)) / 4
      let densityGasInEquipment = this.substance.molarMass * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature)
      let firstCondition = 0.8 * holeArea * Math.sqrt(2 * (this.substance.adiabaticExponent / (this.substance.adiabaticExponent - 1)) * this.equipment.operatingPressure * densityGasInEquipment * (Math.pow(this.ATMOSPHERE_PRESSURE / this.equipment.operatingPressure, 2 / this.substance.adiabaticExponent) - Math.pow(this.ATMOSPHERE_PRESSURE / this.equipment.operatingPressure, (this.substance.adiabaticExponent + 1) / this.substance.adiabaticExponent)))
      let secondCondition = 0.8 * holeArea * Math.sqrt(this.equipment.operatingPressure * densityGasInEquipment * this.substance.adiabaticExponent * Math.pow(2 / (this.substance.adiabaticExponent + 1), (this.substance.adiabaticExponent + 1) / (this.substance.adiabaticExponent - 1)))
      let massSubstanceRate
      if (holeArea <= 0.2 * cutoffPipeCrossSectionalArea) {
        massSubstanceRate = (this.ATMOSPHERE_PRESSURE / this.equipment.operatingPressure) >= Math.pow(2 / (this.substance.adiabaticExponent + 1), this.substance.adiabaticExponent / (this.substance.adiabaticExponent - 1)) ? firstCondition : secondCondition
      } else {
        massSubstanceRate = Number(this.equipment.compressorMassFlowRate)
      }
      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER
      let massSubstanceInCloud = Math.min(massSubstanceRate * this.equipment.cutoffTime + this.substance.molarMass * cutoffPipeVolume * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature), massSubstanceRate * factTimeLiquidationHole)
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },
    calculatePipeConnectedCompressorCompressedGasDestruction() {
      let massSubstanceInEquipment = 0
      let actualSpillArea = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4
      let massSubstanceRate = Number(this.equipment.compressorMassFlowRate)
      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER
      let massSubstanceInCloud = Math.min(massSubstanceRate * this.equipment.cutoffTime + this.substance.molarMass * cutoffPipeVolume * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature), massSubstanceRate * factTimeLiquidationHole)
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },

    calculatePipeConnectedTankFluidPhaseLiquefiedGasHole() {
      let massSubstanceInEquipment = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4
      let equipmentMassGasPhase = (1 - this.equipment.fillRatioTank) * this.substance.molarMass * this.equipment.volumeTank * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature)
      let equipmentMassLiquidPhase = this.substance.substanceDensity * (this.equipment.fillRatioTank * this.equipment.volumeTank + cutoffPipeVolume);
      let holeArea = (Math.PI * Math.pow(this.diameterHole, 2)) / 4
      let dimensionlessPressure = this.equipment.operatingPressure / this.substance.criticalPressure
      let dimensionlessTemperature = this.equipment.operatingTemperature / this.substance.criticalTemperature
      let densityGasPhaseInEquipment = this.substance.molarMass * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature)
      let firstTerm = Math.sqrt(this.substance.criticalPressure * this.substance.molarMass * this.substance.criticalPressure * (0.167 * Math.pow(dimensionlessPressure, 5) + 0.534 * Math.pow(dimensionlessPressure, 1.95)) / (this.UNIVERSAL_GAS_CONST * this.substance.criticalTemperature))
      let secondTerm = Math.sqrt(this.substance.substanceDensity * dimensionlessPressure / densityGasPhaseInEquipment)
      let thirdTerm = 1.22 * Math.pow(dimensionlessTemperature, 3 / 2)
      let massSubstanceRate = 0.8 * holeArea * firstTerm * secondTerm / thirdTerm
      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER
      let massFactLiquidPhase = Math.min(equipmentMassLiquidPhase, massSubstanceRate * factTimeLiquidationHole, massSubstanceRate * this.equipment.cutoffTime + this.substance.substanceDensity * cutoffPipeVolume);
      let massFractionInstantlyEvaporatedLiquidPhase = 1 - Math.exp(-this.substance.specificHeat * (this.region.absoluteMaxTemperature - this.substance.boilingTemperature) / this.substance.specificHeatOfVaporization);
      let massFractionInstantlyEvaporatedLiquidPhaseNew = massFractionInstantlyEvaporatedLiquidPhase >= 0.35 ? 1 : massFractionInstantlyEvaporatedLiquidPhase
      let massInstantlyEvaporatedLiquidPhase = massFractionInstantlyEvaporatedLiquidPhaseNew * massFactLiquidPhase
      let freeSpillArea = this.equipment.surfaceSpreadCoefficient * massFactLiquidPhase * (1 - massFractionInstantlyEvaporatedLiquidPhaseNew) / this.substance.substanceDensity
      let palletAreaFact
      if (this.equipment.palletAvailability) {
        palletAreaFact = this.equipment.efficiencyPalletAtHole ? this.equipment.palletArea : Number.MAX_SAFE_INTEGER
      } else {
        palletAreaFact = Number.MAX_SAFE_INTEGER
      }
      let actualSpillArea = Math.min(palletAreaFact, freeSpillArea)
      let diameterSpill = Math.sqrt(4 * actualSpillArea / Math.PI)
      let normalizedEvaporationTime = massFactLiquidPhase <= 20 ? 900 : 3600
      let factTimeLiquidationSpill = this.equipment.liquidationSpillEmergencyServices ? this.equipment.timeLiquidationSpill : Number.MAX_SAFE_INTEGER
      let theoreticalEvaporationTime = Math.min(normalizedEvaporationTime, factTimeLiquidationSpill)
      let firstTermIntegral = 2 * Math.pow(this.equipment.surfaceThermalConductivity * this.equipment.surfaceSpecificHeat * this.equipment.surfaceDensity * theoreticalEvaporationTime, 0.5) * (this.region.averageMaxTemperature - this.substance.boilingTemperature) / (this.substance.specificHeatOfVaporization * Math.pow(Math.PI, 0.5))
      let secondTermIntegral = 0.035 * Math.pow(this.AIR_FLOW_VELOCITY, 0.8) * this.AIR_THERMAL_CONDUCTIVITY * (this.region.averageMaxTemperature - this.substance.boilingTemperature) * theoreticalEvaporationTime / (Math.pow(this.AIR_KINEMATIC_VISCOSITY, 0.8) * Math.pow(diameterSpill, 0.2) * this.substance.specificHeatOfVaporization)
      let theoreticalEvaporationMass = diameterSpill === 0 ? 0 : (firstTermIntegral + secondTermIntegral) * actualSpillArea
      let factEvaporationMass = theoreticalEvaporationMass <= (massFactLiquidPhase - massInstantlyEvaporatedLiquidPhase) ? theoreticalEvaporationMass : (massFactLiquidPhase - massInstantlyEvaporatedLiquidPhase)
      let massSubstanceInCloud = massFactLiquidPhase === equipmentMassLiquidPhase ? (equipmentMassGasPhase + massInstantlyEvaporatedLiquidPhase + factEvaporationMass) : (massInstantlyEvaporatedLiquidPhase + factEvaporationMass)
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },
    calculatePipeConnectedTankFluidPhaseLiquefiedGasDestruction() {
      let massSubstanceInEquipment = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4
      let cutoffPipeCrossSectionalArea = (Math.PI * Math.pow(pipeDiameter / 1000, 2)) / 4
      let equipmentMassGasPhase = (1 - this.equipment.fillRatioTank) * this.substance.molarMass * this.equipment.volumeTank * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature)
      let equipmentMassLiquidPhase = this.substance.substanceDensity * (this.equipment.fillRatioTank * this.equipment.volumeTank + cutoffPipeVolume)
      let dimensionlessPressure = this.equipment.operatingPressure / this.substance.criticalPressure
      let dimensionlessTemperature = this.equipment.operatingTemperature / this.substance.criticalTemperature
      let densityGasPhaseInEquipment = this.substance.molarMass * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature)
      let firstTerm = Math.sqrt(this.substance.criticalPressure * this.substance.molarMass * this.substance.criticalPressure * (0.167 * Math.pow(dimensionlessPressure, 5) + 0.534 * Math.pow(dimensionlessPressure, 1.95)) / (this.UNIVERSAL_GAS_CONST * this.substance.criticalTemperature))
      let secondTerm = Math.sqrt(this.substance.substanceDensity * dimensionlessPressure / densityGasPhaseInEquipment)
      let thirdTerm = 1.22 * Math.pow(dimensionlessTemperature, 3 / 2)
      let massSubstanceRate = 0.8 * cutoffPipeCrossSectionalArea * firstTerm * secondTerm / thirdTerm
      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER
      let massFactLiquidPhase = Math.min(equipmentMassLiquidPhase, massSubstanceRate * factTimeLiquidationHole, massSubstanceRate * this.equipment.cutoffTime + this.substance.substanceDensity * cutoffPipeVolume)
      let massFractionInstantlyEvaporatedLiquidPhase = 1 - Math.exp(-this.substance.specificHeat * (this.region.absoluteMaxTemperature - this.substance.boilingTemperature) / this.substance.specificHeatOfVaporization)
      let massFractionInstantlyEvaporatedLiquidPhaseNew = massFractionInstantlyEvaporatedLiquidPhase >= 0.35 ? 1 : massFractionInstantlyEvaporatedLiquidPhase
      let massInstantlyEvaporatedLiquidPhase = massFractionInstantlyEvaporatedLiquidPhaseNew * massFactLiquidPhase;
      let freeSpillArea = this.equipment.surfaceSpreadCoefficient * massFactLiquidPhase * (1 - massFractionInstantlyEvaporatedLiquidPhaseNew) / this.substance.substanceDensity
      let palletAreaFact;
      if (this.equipment.palletAvailability) {
        palletAreaFact = this.equipment.efficiencyPalletAtHole ? this.equipment.palletArea : Number.MAX_SAFE_INTEGER
      } else {
        palletAreaFact = Number.MAX_SAFE_INTEGER
      }
      let actualSpillArea = Math.min(palletAreaFact, freeSpillArea)
      let diameterSpill = Math.sqrt(4 * actualSpillArea / Math.PI)
      let normalizedEvaporationTime = massFactLiquidPhase <= 20 ? 900 : 3600
      let factTimeLiquidationSpill = this.equipment.liquidationSpillEmergencyServices ? this.equipment.timeLiquidationSpill : Number.MAX_SAFE_INTEGER
      let theoreticalEvaporationTime = Math.min(normalizedEvaporationTime, factTimeLiquidationSpill)
      let firstTermIntegral = 2 * Math.pow(this.equipment.surfaceThermalConductivity * this.equipment.surfaceSpecificHeat * this.equipment.surfaceDensity * theoreticalEvaporationTime, 0.5) * (this.region.averageMaxTemperature - this.substance.boilingTemperature) / (this.substance.specificHeatOfVaporization * Math.pow(Math.PI, 0.5))
      let secondTermIntegral = 0.035 * Math.pow(this.AIR_FLOW_VELOCITY, 0.8) * this.AIR_THERMAL_CONDUCTIVITY * (this.region.averageMaxTemperature - this.substance.boilingTemperature) * theoreticalEvaporationTime / (Math.pow(this.AIR_KINEMATIC_VISCOSITY, 0.8) * Math.pow(diameterSpill, 0.2) * this.substance.specificHeatOfVaporization)
      let theoreticalEvaporationMass = diameterSpill === 0 ? 0 : (firstTermIntegral + secondTermIntegral) * actualSpillArea;
      let factEvaporationMass = theoreticalEvaporationMass <= (massFactLiquidPhase - massInstantlyEvaporatedLiquidPhase) ? theoreticalEvaporationMass : (massFactLiquidPhase - massInstantlyEvaporatedLiquidPhase)
      let massSubstanceInCloud = massFactLiquidPhase === equipmentMassLiquidPhase ? (equipmentMassGasPhase + massInstantlyEvaporatedLiquidPhase + factEvaporationMass) : (massInstantlyEvaporatedLiquidPhase + factEvaporationMass)
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },
    calculatePipeConnectedPumpFluidPhaseLiquefiedGasHole() {
      let massSubstanceInEquipment = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4
      let cutoffPipeCrossSectionalArea = (Math.PI * Math.pow(pipeDiameter / 1000, 2)) / 4
      let holeArea = (Math.PI * Math.pow(this.diameterHole, 2)) / 4
      let dimensionlessPressure = this.equipment.operatingPressure / this.substance.criticalPressure
      let dimensionlessTemperature = this.equipment.operatingTemperature / this.substance.criticalTemperature
      let densityGasPhaseInEquipment = this.substance.molarMass * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature)
      let firstTerm = Math.sqrt(this.substance.criticalPressure * this.substance.molarMass * this.substance.criticalPressure * (0.167 * Math.pow(dimensionlessPressure, 5) + 0.534 * Math.pow(dimensionlessPressure, 1.95)) / (this.UNIVERSAL_GAS_CONST * this.substance.criticalTemperature))
      let secondTerm = Math.sqrt(this.substance.substanceDensity * dimensionlessPressure / densityGasPhaseInEquipment)
      let thirdTerm = 1.22 * Math.pow(dimensionlessTemperature, 3 / 2)
      let massSubstanceRate = holeArea <= 0.2 * cutoffPipeCrossSectionalArea ? 0.8 * holeArea * firstTerm * secondTerm / thirdTerm : Number(this.equipment.pumpMassFlowRate)
      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER
      let massFactLiquidPhase = Math.min(massSubstanceRate * factTimeLiquidationHole, massSubstanceRate * this.equipment.cutoffTime + this.substance.substanceDensity * cutoffPipeVolume)
      let massFractionInstantlyEvaporatedLiquidPhase = 1 - Math.exp(-this.substance.specificHeat * (this.region.absoluteMaxTemperature - this.substance.boilingTemperature) / this.substance.specificHeatOfVaporization)
      let massFractionInstantlyEvaporatedLiquidPhaseNew = massFractionInstantlyEvaporatedLiquidPhase >= 0.35 ? 1 : massFractionInstantlyEvaporatedLiquidPhase
      let massInstantlyEvaporatedLiquidPhase = massFractionInstantlyEvaporatedLiquidPhaseNew * massFactLiquidPhase
      let freeSpillArea = this.equipment.surfaceSpreadCoefficient * massFactLiquidPhase * (1 - massFractionInstantlyEvaporatedLiquidPhaseNew) / this.substance.substanceDensity
      let palletAreaFact
      if (this.equipment.palletAvailability) {
        palletAreaFact = this.equipment.efficiencyPalletAtHole ? this.equipment.palletArea : Number.MAX_SAFE_INTEGER
      } else {
        palletAreaFact = Number.MAX_SAFE_INTEGER
      }
      let actualSpillArea = Math.min(palletAreaFact, freeSpillArea)
      let diameterSpill = Math.sqrt(4 * actualSpillArea / Math.PI)
      let normalizedEvaporationTime = massFactLiquidPhase <= 20 ? 900 : 3600
      let factTimeLiquidationSpill = this.equipment.liquidationSpillEmergencyServices ? this.equipment.timeLiquidationSpill : Number.MAX_SAFE_INTEGER
      let theoreticalEvaporationTime = Math.min(normalizedEvaporationTime, factTimeLiquidationSpill)
      let firstTermIntegral = 2 * Math.pow(this.equipment.surfaceThermalConductivity * this.equipment.surfaceSpecificHeat * this.equipment.surfaceDensity * theoreticalEvaporationTime, 0.5) * (this.region.averageMaxTemperature - this.substance.boilingTemperature) / (this.substance.specificHeatOfVaporization * Math.pow(Math.PI, 0.5))
      let secondTermIntegral = 0.035 * Math.pow(this.AIR_FLOW_VELOCITY, 0.8) * this.AIR_THERMAL_CONDUCTIVITY * (this.region.averageMaxTemperature - this.substance.boilingTemperature) * theoreticalEvaporationTime / (Math.pow(this.AIR_KINEMATIC_VISCOSITY, 0.8) * Math.pow(diameterSpill, 0.2) * this.substance.specificHeatOfVaporization)
      let theoreticalEvaporationMass = diameterSpill === 0 ? 0 : (firstTermIntegral + secondTermIntegral) * actualSpillArea
      let factEvaporationMass = theoreticalEvaporationMass <= (massFactLiquidPhase - massInstantlyEvaporatedLiquidPhase) ? theoreticalEvaporationMass : (massFactLiquidPhase - massInstantlyEvaporatedLiquidPhase)
      let massSubstanceInCloud = massInstantlyEvaporatedLiquidPhase + factEvaporationMass
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },
    calculatePipeConnectedPumpFluidPhaseLiquefiedGasDestruction() {
      let massSubstanceInEquipment = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4
      let massSubstanceRate = Number(this.equipment.pumpMassFlowRate)
      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER
      let massFactLiquidPhase = Math.min(massSubstanceRate * factTimeLiquidationHole, massSubstanceRate * this.equipment.cutoffTime + this.substance.substanceDensity * cutoffPipeVolume)
      let massFractionInstantlyEvaporatedLiquidPhase = 1 - Math.exp(-this.substance.specificHeat * (this.region.absoluteMaxTemperature - this.substance.boilingTemperature) / this.substance.specificHeatOfVaporization)
      let massFractionInstantlyEvaporatedLiquidPhaseNew = massFractionInstantlyEvaporatedLiquidPhase >= 0.35 ? 1 : massFractionInstantlyEvaporatedLiquidPhase
      let massInstantlyEvaporatedLiquidPhase = massFractionInstantlyEvaporatedLiquidPhaseNew * massFactLiquidPhase
      let freeSpillArea = this.equipment.surfaceSpreadCoefficient * massFactLiquidPhase * (1 - massFractionInstantlyEvaporatedLiquidPhaseNew) / this.substance.substanceDensity
      let palletAreaFact
      if (this.equipment.palletAvailability) {
        palletAreaFact = this.equipment.efficiencyPalletAtDestruction ? this.equipment.palletArea : Number.MAX_SAFE_INTEGER
      } else {
        palletAreaFact = Number.MAX_SAFE_INTEGER
      }
      let actualSpillArea = Math.min(palletAreaFact, freeSpillArea)
      let diameterSpill = Math.sqrt(4 * actualSpillArea / Math.PI)
      let normalizedEvaporationTime = massFactLiquidPhase <= 20 ? 900 : 3600
      let factTimeLiquidationSpill = this.equipment.liquidationSpillEmergencyServices ? this.equipment.timeLiquidationSpill : Number.MAX_SAFE_INTEGER
      let theoreticalEvaporationTime = Math.min(normalizedEvaporationTime, factTimeLiquidationSpill)
      let firstTermIntegral = 2 * Math.pow(this.equipment.surfaceThermalConductivity * this.equipment.surfaceSpecificHeat * this.equipment.surfaceDensity * theoreticalEvaporationTime, 0.5) * (this.region.averageMaxTemperature - this.substance.boilingTemperature) / (this.substance.specificHeatOfVaporization * Math.pow(Math.PI, 0.5))
      let secondTermIntegral = 0.035 * Math.pow(this.AIR_FLOW_VELOCITY, 0.8) * this.AIR_THERMAL_CONDUCTIVITY * (this.region.averageMaxTemperature - this.substance.boilingTemperature) * theoreticalEvaporationTime / (Math.pow(this.AIR_KINEMATIC_VISCOSITY, 0.8) * Math.pow(diameterSpill, 0.2) * this.substance.specificHeatOfVaporization)
      let theoreticalEvaporationMass = diameterSpill === 0 ? 0 : (firstTermIntegral + secondTermIntegral) * actualSpillArea
      let factEvaporationMass = theoreticalEvaporationMass <= (massFactLiquidPhase - massInstantlyEvaporatedLiquidPhase) ? theoreticalEvaporationMass : (massFactLiquidPhase - massInstantlyEvaporatedLiquidPhase)
      let massSubstanceInCloud = massInstantlyEvaporatedLiquidPhase + factEvaporationMass
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },

    calculatePipeConnectedTankVaporPhaseLiquefiedGasHole() {
      let massSubstanceInEquipment = 0
      let actualSpillArea = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4
      let equipmentMassVaporPhase = this.substance.molarMass * this.equipment.operatingPressure * ((1 - this.equipment.fillRatioTank) * this.equipment.volumeTank + cutoffPipeVolume) / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature)
      let equipmentMassLiquidPhase = this.substance.substanceDensity * this.equipment.fillRatioTank * this.equipment.volumeTank
      let totalMassSubstanceInEquipment = equipmentMassVaporPhase + equipmentMassLiquidPhase
      let holeArea = (Math.PI * Math.pow(this.diameterHole, 2)) / 4
      let dimensionlessPressure = this.equipment.operatingPressure / this.substance.criticalPressure
      let firstTerm = Math.sqrt(this.substance.criticalPressure * this.substance.molarMass * this.substance.criticalPressure * (0.167 * Math.pow(dimensionlessPressure, 5) + 0.534 * Math.pow(dimensionlessPressure, 1.95)) / (this.UNIVERSAL_GAS_CONST * this.substance.criticalTemperature))
      let massSubstanceRate = 0.8 * holeArea * firstTerm
      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER;
      let massSubstanceInCloud = Math.min(totalMassSubstanceInEquipment, massSubstanceRate  * factTimeLiquidationHole, massSubstanceRate * this.equipment.cutoffTime + this.substance.molarMass * cutoffPipeVolume * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature))
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },
    calculatePipeConnectedTankVaporPhaseLiquefiedGasDestruction() {
      let massSubstanceInEquipment = 0
      let actualSpillArea = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let cutoffPipeCrossSectionalArea = (Math.PI * Math.pow(pipeDiameter / 1000, 2)) / 4
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4
      let equipmentMassVaporPhase = this.substance.molarMass * this.equipment.operatingPressure * ((1 - this.equipment.fillRatioTank) * this.equipment.volumeTank + cutoffPipeVolume) / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature)
      let equipmentMassLiquidPhase = this.substance.substanceDensity * this.equipment.fillRatioTank * this.equipment.volumeTank
      let totalMassSubstanceInEquipment = equipmentMassVaporPhase + equipmentMassLiquidPhase
      let dimensionlessPressure = this.equipment.operatingPressure / this.substance.criticalPressure
      let firstTerm = Math.sqrt(this.substance.criticalPressure * this.substance.molarMass * this.substance.criticalPressure * (0.167 * Math.pow(dimensionlessPressure, 5) + 0.534 * Math.pow(dimensionlessPressure, 1.95)) / (this.UNIVERSAL_GAS_CONST * this.substance.criticalTemperature))
      let massSubstanceRate = 0.8 * cutoffPipeCrossSectionalArea * firstTerm
      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER;
      let massSubstanceInCloud = Math.min(totalMassSubstanceInEquipment, massSubstanceRate  * factTimeLiquidationHole, massSubstanceRate * this.equipment.cutoffTime + this.substance.molarMass * cutoffPipeVolume * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature))
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },
    calculatePipeConnectedCompressorVaporPhaseLiquefiedGasHole() {
      let actualSpillArea = 0
      let massSubstanceInEquipment = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4
      let cutoffPipeCrossSectionalArea = (Math.PI * Math.pow(pipeDiameter / 1000, 2)) / 4
      let holeArea = (Math.PI * Math.pow(this.diameterHole, 2)) / 4
      let dimensionlessPressure = this.equipment.operatingPressure / this.substance.criticalPressure
      let firstTerm = Math.sqrt(this.substance.criticalPressure * this.substance.molarMass * this.substance.criticalPressure * (0.167 * Math.pow(dimensionlessPressure, 5) + 0.534 * Math.pow(dimensionlessPressure, 1.95)) / (this.UNIVERSAL_GAS_CONST * this.substance.criticalTemperature))
      let massSubstanceRate = holeArea <= 0.2 * cutoffPipeCrossSectionalArea ? 0.8 * holeArea * firstTerm : Number(this.equipment.compressorMassFlowRate)
      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER
      let massSubstanceInCloud = Math.min(massSubstanceRate * factTimeLiquidationHole, massSubstanceRate * this.equipment.cutoffTime + this.substance.molarMass * cutoffPipeVolume * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature))
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },
    calculatePipeConnectedCompressorVaporPhaseLiquefiedGasDestruction() {
      let actualSpillArea = 0
      let massSubstanceInEquipment = 0
      const pipeDiameter = this.equipment.cutoffPipeDiameter || this.pipeDepressurizationData.pipelineDiameter
      let cutoffPipeVolume = (Math.PI * Math.pow(pipeDiameter / 1000, 2) * this.equipment.cutoffPipeLength) / 4
      let massSubstanceRate = Number(this.equipment.compressorMassFlowRate)
      let factTimeLiquidationHole = this.equipment.liquidationHoleEmergencyServices ? this.equipment.timeLiquidationHole : Number.MAX_SAFE_INTEGER
      let massSubstanceInCloud = Math.min(massSubstanceRate * factTimeLiquidationHole, massSubstanceRate * this.equipment.cutoffTime + this.substance.molarMass * cutoffPipeVolume * this.equipment.operatingPressure / (this.UNIVERSAL_GAS_CONST * this.equipment.operatingTemperature))
      return { massSubstanceInEquipment, massSubstanceRate, actualSpillArea, massSubstanceInCloud }
    },

    addNewTree() {
      const newTree = { id: Date.now(), rows: [] }
      if(this.treesSteps === 1) {
        const cols = []
        cols.push({ value: undefined, empty: false, })
        newTree.rows.push({ fireVariant: 0, result: undefined, cols })
        newTree.rows.reverse()
        this.selectedAccident.trees.push(newTree)
      } else {
        let i = 0
        while (i !== this.treesSteps) {
          const cols = []
          if (i === this.treesSteps - 1) {
            cols.push({ value: undefined, empty: false, }, { value: undefined, empty: false, maxValue: 1})
            for (let y = 0; y < this.treesSteps - 2; y++) {
              cols.push({ empty: true })
            }
          } else if (i > 0) {
            cols.push({ result: undefined, empty: false, }, { value: undefined, empty: false, maxValue: 1})
            for (let y = 0; y < i - 1; y++) {
              cols.push({ empty: true })
            }
          } else {
            cols.push({ result: undefined, empty: false, maxValue: 1})
          }
          newTree.rows.push({ fireVariant: 0, result: undefined, cols })
          i++
        }
        newTree.rows.reverse()
        this.selectedAccident.trees.push(newTree)
      }
    },

    calculateEventTrees() {
      this.selectedAccident.trees.map((element, indx) => {
        let multy = 1;
        var mainValue = 0

        if(element.rows[0].cols[0].value !== undefined && element.rows[0].cols[0].value !== null) {
          mainValue = evaluate(element.rows[0].cols[0].value)
        }
        
        this.selectedAccident.trees[indx].frequencyScenario = []
        this.selectedAccident.trees[indx].fires = []

        element.rows.map((rowElement, rindx) => {
          if(rindx > 0) { 
            multy = mainValue
            if(rindx > 1) {
              for (let i = 0; i < rindx - 1; i++) {
                multy *= parseFloat(element.rows[rindx - (i + 1)].cols[0].result)
              }
            }
          }
          rowElement.cols.map((element) => {
            if((element.value !== undefined && element.value !== null) || (element.result !== undefined && element.result !== undefined)){
              let val = element.value || element.result
              val = parseFloat(evaluate(val))
              multy *= val
              multy = multy.toExponential(3);
            } else if(!element.empty) {
              multy = null
            }
          })
          rowElement.result = multy
          let selectFrequencyScenario = multy;
          this.selectedAccident.trees[indx].frequencyScenario.push(parseFloat(selectFrequencyScenario))
          this.selectedAccident.trees[indx].fires.push(this.fireVariants[rowElement.fireVariant])
        })
      })

      if(this.selectedAccident && this.selectedAccident.canUseTree){
        const currentAccidentIndex = this.accidents.findIndex(el => el.name === this.activeTab)
        setTimeout(() => {
          html2canvas(document.querySelector(".accidents__tree"), { allowTaint: true, useCORS: true }).then(canvas => {
            let treesPhotoInfo = {
              width:canvas.width,
              height:canvas.height,
              image:canvas.toDataURL("image/png"),
            }

            this.$store.commit('Accidents/setTreesPhoto', 
            { index: currentAccidentIndex, 
            data: treesPhotoInfo, })

            this.$store.commit('Accidents/setTreesChanges', 
            { index: currentAccidentIndex, 
            parameters: this.selectedAccident.trees, 
            label: 'failureParameterObj' })
          })
        }, 100) 
      }
    },

    calculateTreeBranchResult(prevRow, currentRow) {
      let resul = prevRow.cols[1].value
      if(resul) {
        currentRow.cols[0].result = Math.round((1 - ((resul - 1) * - 1) === 0 ? 0 : ((resul - 1) * - 1)) * 1000) / 1000
        return Math.round((1 - ((resul - 1) * - 1) === 0 ? 0 : ((resul - 1) * - 1)) * 1000) / 1000
      }
    },

    addNewAccident() {
      this.accidents.push({
        title: `Авария ${this.accidents.length + 1}`,
        name: `${this.accidents.length + 1}`,
        characteristic: '',
        value: 0,
        failure: 'hole',
        diameterHole: null,
        diameterTank: null,
        massSubstanceInEquipment: null,
        massSubstanceRate: null,
        actualSpillArea: null,
        massSubstanceInCloud: null,
        instantaneousIgnitionProbability: null,
        subsequentIgnitionProbability: null,
        cloudExplosionProbability: null,
        absenceSubsequentIgnitionProbability: null,
        absenceCalmProbability: null,
        trees: [],
        frequencyScenario: [],
        fires: [],
      })
      this.activeTab = `${this.accidents.length}`
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.accidents {
  &__tabs {
    &-header {
      display: flex;
      align-items: flex-end;
      border-bottom: 1px solid $lightGrey;
      padding: 0 3rem 5rem;

      &-items {
        display: flex;
      }

      &-item {
        margin-right: 2.4rem;
        border-bottom: 2px solid $lightGrey;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: $basicBlack;
        padding: 1.5rem 2rem;
        cursor: pointer;
        transition: 0.3s ease-out;

        &.active,
        &:hover {
          color: $blue;
          border-bottom: 2px solid $blue;
        }
      }

      &-add {
        background: $lightGrey;
        border-radius: 1rem;
        height: 5rem;
        width: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s ease-out;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $lightGrey;
    padding: 2rem 3rem;

    &-label {
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $basicBlack;
    }

    &-input {
      width: 40rem;
    }

    &-actions {
      margin-top: 1.2rem;

      button {
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 1.6rem;
        background: none;
        border: none;
        margin-right: 4rem;
        color: $blue;
        transition: 0.3s ease-out;

        &:hover {
          color: $darkGrey;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    border-bottom: 1px solid $lightGrey;
  }

  &__results {
    padding: 2rem 3rem;
    border-bottom: 1px solid $lightGrey;

    &-item {
      display: flex;
      align-items: flex-end;
      font-size: 1.4rem;
      line-height: 1.9rem;
      margin-bottom: 1rem;
    }

    &-name {
      color: $basicBlack;

      sup {
        font-size: 1.2rem;
      }
    }

    &-value {
      font-weight: 600;
      margin-left: 1.2rem;
      color: $basicBlack;
    }
  }

  &__probability {
    padding: 2rem 3rem;
    border-bottom: 1px solid #eff2f6;
  } 
  
  &__trees {
    padding: 6rem 5rem 0;
    overflow: auto;
  }

  &__tree {
    border-bottom: 1px solid $lightGrey;
    padding-top: 2rem;
    width: max-content;

    &-row {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 2rem;
    }

    &-col {
      width: 10rem;
      margin-right: 1rem;
      border-bottom: 1px solid $basicBlack;
      padding-bottom: 5px;

      &-input {
        padding-left: 15px;
        padding-right: 15px;
        border: 1px solid #C8D0D6;
        border-radius: 1rem;
        height: 4.8rem;
        padding: 1rem 1.6rem;
        font-weight: 500;
        font-size: 1.4rem;
        color: #2F2F2F;
        width: 100%;
      }

      &.result {
        border-left: 1px solid $basicBlack;
        border-bottom: 1px solid $basicBlack;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-select {
      width: 22rem;
    }

    &-result {
      display: flex;
      align-items: center;
      margin-left: .8rem;
      width: 10rem;
    }
  }
}

</style>
