import axios from 'axios'
const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
})
const getters = {}
const mutations = {
  setData(state, data) {
    state[data.label] = data.data
  },
  
}
const actions = {
  getAdminSubstances (ctx, params) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/admin/materials', method: 'GET', params: params })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addAdminSubstance (ctx , data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/admin/material', data: data.data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changeAdminSubstance (ctx , data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/admin/material/${data.id}`, data: data.data , method: 'PUT' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  dellSubstance(ctx, id) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/admin/material/${id}`, method: 'DELETE' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSubstance (ctx , id) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/material/${id}`, method: 'GET' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSubstances (ctx, params) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/materials', method: 'GET', params: params })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
