export const DateParser = {
    methods: {
      fixedDate(date) {
        if(date) {
          const parseDate = new Date(date)
          return `${ ('0' + parseDate.getDate()).slice(-2) }.${ ('0' + (parseDate.getMonth()+1)).slice(-2)}.${ parseDate.getFullYear() }`
        }
      },
      getDemoDays(date) {
        if(date) {
          let curentDate = new Date()
          let experationDate = new Date(date)
          return ((experationDate - curentDate) / 86400000).toFixed()
        }
      },
    }
  }