<template>
  <div class="add-people">
    <div class="add-people__row">
      <div class="add-people__label">
        Название селитебной зоны
      </div>
      <el-input v-model="name" />
    </div>
    <div class="add-people__row">
      <div class="add-people__label">
        Описание селитебной зоны <span class="required-field">*</span> 
      </div>
      <el-input v-model="description" :rows="3" type="textarea" />
    </div>
    <div class="add-people__row">
      <div class="add-people__label">
        Число людей в селитебной зоне
      </div>
      <el-input type="number" v-model="numberOfPersons"/>
    </div>
    <div class="add-people__row">
      <div class="add-people__label">
        Вероятность присутствия людей в селитебной зоне
      </div>
      <el-input type="number" v-model="probabilityOfPresencePerson"/>
    </div>
    <div class="add-people__buttons">
      <ButtonElement text="Отмена" @click="cancel"/>
      <ButtonElement
        text="Сохранить"
        :disabled="!name || !numberOfPersons || !probabilityOfPresencePerson"
        @click="editData ? editLivingArea() : saveLivingArea()"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from '../elements/ButtonElement'

export default {
  name: 'AddPeople',
  components: {
    ButtonElement,
  },
  data() {
    return {
      name: '',
      description: '',
      numberOfPersons: null,
      probabilityOfPresencePerson: 1,
    }
  },
  created() {
    if(this.editData) {
      for(let key in this.editData) {
        this[key] = this.editData[key]
      }
    }
  },
  computed: {
    editData() {
      return this.$store.state.Project.currentEditObject
    }
  },
  methods: {
    saveLivingArea() {
      if(this.name && this.numberOfPersons && this.probabilityOfPresencePerson) {
        const arrPointZone = this.$store.state.Project.currentPeoplePosition
        this.$store.commit('Project/addNewZone', {
          id: Date.now(),
          name: this.name,
          description: this.description,
          numberOfPersons: this.numberOfPersons,
          probabilityOfPresencePerson: this.probabilityOfPresencePerson,
          arrPosZone: this.$store.state.Project.currentPeoplePosition,
        })
        this.$store.commit('Project/addDrawPoints', { data: arrPointZone, catName: 'Zone' })
        this.$store.commit('Project/setData', { label: 'selectedSwitcherName', data: 'Население' })
        this.$store.commit('Project/setData', { label: 'selectedSwitcher', data: !this.$store.state.Project.selectedSwitcher })
        this.$store.dispatch('Dialog/closeDialog')
      } else {
        this.errorNotify('Заполните обязательные поля')
      }
    },
    editLivingArea() {
      if(this.name && this.numberOfPersons && this.probabilityOfPresencePerson) {
        this.$store.commit('Project/editZone', {
          id: this.id,
          name: this.name,
          description: this.description,
          numberOfPersons: this.numberOfPersons,
          probabilityOfPresencePerson: this.probabilityOfPresencePerson,
        })
        this.$store.dispatch('Dialog/closeDialog')
      } else {
        this.errorNotify('Заполните обязательные поля')
      }
    },
    cancel() {
      this.$store.commit('Project/setData', { label: 'currentEditObject', data: null })
      this.$store.dispatch('Dialog/closeDialog')
      this.$store.commit('Dialog/setData', { label: 'redrawCanvasAfterCloseDialog', data: true })
    },    
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

.add-people {
  &__row {
    border-bottom: 1px solid $lightGrey;
    padding: 2rem 3rem;
  }

  &__label {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $basicBlack;
    margin-bottom: 0.8rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    padding: 4rem 0;
  }
}
</style>
