<template>
  <div class="add-building">
    <div class="add-building__row">
      <div class="add-building__label">
        Наименование
      </div>
      <el-input v-model="name" />
    </div>
    <div class="add-building__row">
      <div class="add-building__label">
        Описание <span class="required-field">*</span>
      </div>
      <el-input v-model="description" :rows="3" type="textarea" />
    </div>
    <div class="add-building__row">
      <div class="add-building__label">
        Введите значение потенциального риска в здании, 1/год 
      </div>

      <el-input type="number" v-model="potRiskInBuilding"/>
    </div>
    <div class="add-building__buttons">
      <ButtonElement text="Отмена" @click="cancel"/>
      <ButtonElement
        text="Сохранить"
        :disabled="!name || !potRiskInBuilding"
        @click="editData ? editBuilding() : saveBuilding()"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from '../elements/ButtonElement'
import { AlertNotify } from '@/mixin/alert_notify'

export default {
  name: 'AddBuilding',
  mixins: [AlertNotify],
  components: {
    ButtonElement,
  },
  data() {
    return {
      name: '',
      description: '',
      potRiskInBuilding: null,
    }
  },
  created() {
    if(this.editData) {
      for(let key in this.editData) {
        this[key] = this.editData[key]
      }
    }
  },
  computed: {
    editData() {
      return this.$store.state.Project.currentEditObject
    }
  },
  methods: {
    saveBuilding() {
      if(this.name && this.potRiskInBuilding) {
        const arrPointBuilding = this.$store.state.Project.currentBuildingPosition
        this.$store.commit('Project/addNewBuilding', {
          id: Date.now(),
          name: this.name,
          description: this.description,
          potRiskInBuilding: this.potRiskInBuilding,
          arrPosBuilding: this.$store.state.Project.currentBuildingPosition,
        })
        this.$store.commit('Project/addDrawPoints', { data: arrPointBuilding, catName: 'Building' })
        this.$store.dispatch('Dialog/closeDialog')

        this.$store.commit('Project/setData', { label: 'selectedSwitcherName', data: 'Здания' })
        this.$store.commit('Project/setData', { label: 'selectedSwitcher', data: !this.$store.state.Project.selectedSwitcher })
        
      } else {
        this.errorNotify('Заполните обязательные поля')
      }
    },
    editBuilding() {
      if(this.name && this.potRiskInBuilding) {
        this.$store.commit('Project/editBuilding', {
          id: this.id,
          name: this.name,
          description: this.description,
          potRiskInBuilding: this.potRiskInBuilding,
        })
        this.$store.dispatch('Dialog/closeDialog')
      } else {
        this.errorNotify('Заполните обязательные поля')
      }
    },
    cancel() {
      this.$store.commit('Project/setData', { label: 'currentEditObject', data: null })
      this.$store.dispatch('Dialog/closeDialog')
      this.$store.commit('Dialog/setData', { label: 'redrawCanvasAfterCloseDialog', data: true })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

.add-building {
  &__row {
    border-bottom: 1px solid $lightGrey;
    padding: 2rem 3rem;
  }

  &__label {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $basicBlack;
    margin-bottom: 0.8rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    padding: 4rem 0;
  }
}
</style>
