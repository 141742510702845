<template>
  <div class="tarif_button_container">
    <ButtonElement 
      :text="`Продлить еще на ${this.$store.state.Personal.user.last_product.product.name}`" 
      @click="buyProduct"
    />
    <ButtonElement 
      text="Выбрать другой тариф" 
      @click="goToTariffs"
    />
  </div>
</template>

<script>
import ButtonElement from '../elements/ButtonElement'
import { ElMessageBox } from 'element-plus'

export default {
  name: 'TariffDialog',
  components: {
    ButtonElement,
  },
  methods: {
    goToTariffs() {
      this.$router.push('/tariffs')
      this.$store.dispatch('Dialog/closeDialog')
    },
    buyProduct() {
      const data = { id: this.$store.state.Personal.user.last_product.product.id } 
      this.$store.dispatch('Dialog/closeDialog')
      if(data){
        this.$store.dispatch('Products/buyProduct', data)
          .then(resp => {
            ElMessageBox.alert(`Вы будете перенаправлены на страницу оплаты через 3 сек.`, {
              customClass: 'big-notification',
              showConfirmButton: false,
              showClose: false,
            })
            setTimeout(() => {
              document.location.href = resp.data.data.confirmation_url
            }, 3000);
          })
          .catch(err => {
            console.log(err.response.data.errors)
          })
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

  .tarif_button_container{
    padding: 40px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .button{
      height: 56px;
      width: 250px;
      margin: 20px;
      font-size: 14px;
    }
  }
</style>
