<template>
  <div class="detail-charts">
    <div v-if="graphTableData || graphDestructionTableData" class="view-machine__table">
      <el-table v-if="graphDestructionTableData" :data="destructionTableData" border style="width: 100%">
        <el-table-column label="Номер точки" type="index" :index="indexMethod" align="center" width="80" />
        <el-table-column label="Степень поражения" header-align="center">
          <template #default="scope" >
            <div :key="scope.row.id" v-html="scope.row.degree"></div>
          </template>
        </el-table-column>
        <el-table-column prop="overpressure" label="Избыточное давление, кПа" align="center" width="140"/>
        <el-table-column prop="distance" label="Расстояние от оборудования, м" align="center" width="140"/>
      </el-table>

      <el-table v-else :data="tableData" border style="width: 100%">
        <el-table-column label="Номер точки" type="index" :index="indexMethod" align="center" width="80" />
        <el-table-column label="Степень поражения" header-align="center">
          <template #default="scope" >
            <div :key="scope.row.id" v-html="scope.row.degree"></div>
          </template>
        </el-table-column>
        <el-table-column prop="radiation" label="Интенсивность излучения, кВт/м2" align="center" width="140"/>
        <el-table-column prop="distance" label="Расстояние от оборудования, м" align="center" width="140"/>
      </el-table>
    </div>

    <div v-if="detailChartInfo" class="view-machine__chart-info"> 
      {{ detailChartInfo }}
    </div>

    <div v-if="chartData && chartOptions" class="chart-box">
      <LineChart :chartData="chartData" :options="chartOptions"/>
    </div>
    <div v-if="probabilityChartData && probabilityChartOptions" class="chart-box">
     <LineChart :chartData="probabilityChartData" :options="probabilityChartOptions"/>
    </div>

    <ButtonElement text="Отмена" @click="cancel"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { LineChart } from 'vue-chart-3';
import ButtonElement from '../elements/ButtonElement'

export default {
  name: 'ChartsDialog',
  components: {
    ButtonElement,LineChart
  },

  data() {
    return {
      tableData: [
        {
          degree: 
          `Непереносимая боль через 3-5 с <br>
          Ожог 1 степени через 6-8 с <br>
          Ожог 2 степени через 12-16 с`,
          radiation: '10,5',
          distance: '',
        },
        {
          degree: 
          `Непереносимая боль через 20-30 с <br>
          Ожог 1 степени через 15-20 с <br>
          Ожог 2 степени через 30-40 с`,
          radiation: '7,0',
          distance: '',
        },
        {
          degree: `Безопасно для человека в брезентовой одежде`,
          radiation: '4,2',
          distance: '',
        },
        {
          degree: `Без негативных последствий в течение длительного времени`,
          radiation: '1,4',
          distance: '',
        },
      ],

      destructionTableData: [
        {
          degree: `Полное разрушение зданий`,
          overpressure: '100',
          distance: '',
        },
        {
          degree: `50 %-ное разрушение зданий`,
          overpressure: '53',
          distance: '',
        },
        {
          degree: `Средние повреждения зданий`,
          overpressure: '28',
          distance: '',
        },
        {
          degree: `Умеренные повреждения зданий (повреждение внутренних перегородок, рам, дверей и т.п.)`,
          overpressure: '12',
          distance: '',
        },
      ],
    }
  },
  computed: {
    ...mapState('Project', [
      'chartData',
      'chartOptions',
      'probabilityChartData',
      'probabilityChartOptions',
      'graphTableData',
      'graphDestructionTableData',
      'detailChartInfo',
    ]),
  },

  mounted() {
    if(this.graphTableData){
      this.graphTableData.map((el,indx) => {
        this.tableData[indx].distance = el
      })
    } else if(this.graphDestructionTableData) {
      this.graphDestructionTableData.map((el,indx) => {
        this.destructionTableData[indx].distance = el
      })
    }
  },

  methods: {
    cancel() {
      this.$store.commit('Project/setData', { label: 'currentEditObject', data: null })
      this.$store.dispatch('Dialog/closeDialog')
      this.$store.commit('Dialog/setData', { label: 'redrawCanvasAfterCloseDialog', data: true })
    },    
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';


.detail-charts {
  padding: 5rem;
}

.view-machine__table {
  margin-bottom: 4rem;
}

.view-machine__chart-info {
  padding: 0 2rem;
  margin-bottom: 4rem;
  font-size: 1.6rem;
}

.chart-box {
  height: 50rem;
  margin-bottom: 4rem;

  > div {
    height: 100%;
  }
}

</style>
