<template>
  <div class="new-project">
    <div class="new-project__row">
      <div class="new-project__label">
        Название проекта
      </div>
      <div class="new-project__input">
        <el-input v-model="form.name" />
      </div>
    </div>
    <div class="new-project__row">
      <div class="new-project__label">
        Описание <span class="required-field">*</span> 
      </div>
      <div class="new-project__input">
        <el-input
          v-model="form.description"
          :rows="3"
          type="textarea"
          resize="none"
        />
      </div>
    </div>
    <div class="new-project__row">
      <el-upload
        drag
        action="#"
        list-type="picture"
        :limit="1"
        :on-change="uploadedFile"
        :auto-upload="false"
      >
        <div class="new-project__upload">
          <img src="../../assets/icons/icon-picture.svg" alt="">
          Загрузите или перетяните изображение сюда
        </div>
      </el-upload>
    </div>
    <div class="new-project__form-text">Город расположения производственного объекта</div>
    <div class="new-project__row new-project__row--horizontal">
      <div class="new-project__label">
        Город расположения производственного объекта
      </div>
      <div class="new-project__input">
        <el-select 
          v-model="form.city_id" 
          @change="changeCity"
          filterable
          remote
          reserve-keyword
        >
          <el-option
            v-for="item in citiesForUser"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
          <el-option label="Новый город" value="new" />
        </el-select>
      </div>
    </div>
    <AddNewCity v-if="showFormForNewCity" ref="new-city" />
    <div class="new-project__form-text">Характеристика окружающего пространства</div>
    <div class="new-project__row new-project__row--horizontal">
      <div class="new-project__label">
        Класс окружающего пространства по степени загроможденности
      </div>
      <div class="new-project__input">
        <el-select v-model="form.class">
          <el-option
            v-for="item in classes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div class="new-project__info">
      <p>
        Класс I - наличие длинных труб, полостей, каверн, заполненных горючей смесью, при сгорании которой возможно ожидать формирование турбулентных струй продуктов сгорания, имеющих размеры не менее трех размеров детонационной ячейки
        данной смеси.
      </p>
      <p>
        Класс II - сильно загроможденное пространство: наличие полузамкнутых объемов высокая плотность размещения
        технологического оборудования, лес, большое количество повторяющихся препятствий.
      </p>
      <p>
        Класс III - средне загроможденное пространство: отдельно стоящие технологические установки, резервуарный парк.
      </p>
      <p>
        Класс IV - слабо загромождение и свободное пространство.
      </p>
    </div>
    <div class="new-project__actions">
      <ButtonElement text="Отмена" @click="$store.dispatch('Dialog/closeDialog')" />
      <ButtonElement
        text="Создать"
        :disabled="!this.form.name || !this.form.file"
        @click="createNewProject"
      />
      <ButtonElement
        text="Далее"
        :disabled="!this.form.name || !this.form.file"
        @click="createNewProject('goToProject')"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from '../elements/ButtonElement'
import AddNewCity from '../AddNewCity'
import { ElMessage } from 'element-plus'

export default {
  name: 'NewProject',
  components: {
    ButtonElement,
    AddNewCity,
  },
  data() {
    return {
      showFormForNewCity: false,
      form: {
        name: 'Проект №1',
        description: '',
        file: '',
        city_id: 1,
        class: 'class_I',
      },
      classes: [
        {
          label: 'Класс I',
          value: 'class_I'
        },
        {
          label: 'Класс II',
          value: 'class_II'
        },
        {
          label: 'Класс III',
          value: 'class_III'
        },
        {
          label: 'Класс IV',
          value: 'class_IV'
        },
      ]
    }
  },
  computed: {
    citiesForUser() {
      return this.$store.state.Cities.citiesForUser
    },
  },
  watch: {
    citiesForUser: {
      deep: true,
      handler() {
        this.form.city_id = this.citiesForUser[this.citiesForUser.length - 1].id
        this.showFormForNewCity = false
      },
    }
  },
  created() {
    this.$store.dispatch('Cities/getCitiesForUser')
    .then(() => {
      if(this.citiesForUser && this.citiesForUser.length) this.form.city_id = this.citiesForUser[0].id
    })
  },
  mounted() {
    const projects = this.$store.state.Project.projectsList
    if (projects && projects.length) {
      this.form.name = 'Проект №' + (projects.length + 1)
    }
  },
  methods: {
    changeCity(val) {
      if (val !== 'new') {
        const data = {
          absoluteMaxTemperature: 311,
          averageMaxTemperature: 296,
          northWindVelocity: 3.1,
          northEastWindVelocity: 3.1,
          eastWindVelocity: 2.6,
          southEastWindVelocity: 3,
          southWindVelocity: 2.9,
          southWestWindVelocity: 3.1,
          westWindVelocity: 3.3,
          northWestWindVelocity: 3.3,
          calm: 0.12,
        }
        this.$store.commit('Project/setData', { label: 'region', data })
        this.showFormForNewCity = false
      } else {
        this.showFormForNewCity = true
      }
    },
    uploadedFile(response) {
      this.form.file = response.raw
    },
    createNewProject(action) {
      if(this.showFormForNewCity){
          ElMessage({
            type: 'error',
            message: 'Завершите создание новго города, или выберите город из списка',
          })
          return
      }
      const formData = new FormData()
      for (const key in this.form) {
        if(key === 'description') {
          if(this.form.description) {
            formData.append(key, this.form[key])
          }
        } else {
          formData.append(key, this.form[key])
        }
      }
      this.$store.dispatch('Project/createProjects', formData)
        .then(({ data }) => {
          if (data.success) {
            this.$store.dispatch('Dialog/closeDialog')
            if(action && action === 'goToProject') {
              setTimeout(() => {
                this.$router.push(`/projects/${data.data.id}`)
              }, 300)
            } else {
              this.$store.dispatch('Project/getProjects', this.$route.query)
            }
          }
        })
        .catch(() => {
          ElMessage({
            type: 'error',
            message: 'Ошибка',
          })
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

.new-project {
  &__row {
    border-bottom: 1px solid $lightGrey;
    padding: 2rem 3rem;

    &--horizontal {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .new-project__input {
        width: 24rem;
      }
    }
  }

  &__upload {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $blue;

    & > img {
      margin-right: 1.2rem;
    }
  }

  &__info {
    font-size: 1.2rem;
    line-height: 140%;
    color: $darkGrey;
    padding: 3rem;

    p {
      margin-bottom: 1.7rem;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 5.3rem;
    padding-bottom: 5rem;
  }

  &__label {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $basicBlack;
    margin-bottom: 1rem;
  }

  &__form-text {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.5rem;
    margin: 3rem 0;
    padding: 0 3rem;
  }
}
</style>
