<template>
  <el-dialog
    v-model="dialogNotificationVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :width="fit-content"
    :custom-class="`base-notification ${activeDialog === 'SetNorth' ? 'small' : ''}`"
  >
    <component :is="activeDialog" />
  </el-dialog>
</template>

<script>
import SetDistance from './SetDistance'
import SetNorth from './SetNorth'
import Confirm from './Confirm'

export default {
  name: 'NotificationsBase',
  components: {
    SetDistance,
    SetNorth,
    Confirm,
  },
  computed: {
    dialogNotificationVisible: {
      get() {
        return this.$store.state.Dialog.dialogNotificationVisible
      },
      set(val) {
        if (!val) this.$store.commit('Dialog/setData', { label: 'activeDialog', data: '' })
        this.$store.commit('Dialog/setData', { label: 'dialogNotificationVisible', data: val })
      },
    },
    activeDialog() {
      return this.$store.state.Dialog.activeDialog
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

.el-dialog.base-notification {
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 1.2rem;

  &.small {
    width: fit-content !important;
  }

  .el-dialog {
    &__header {
      padding: 0;
    }

    &__body {
      padding: 2rem;
    }
  }
}
</style>
