<template>
  <div class="account__form">
    <el-form
      :model="form"
      ref="userForm"
      :show-message="false"
      :rules="rules"
      class="change-user-form"
    >
      <el-form-item
          label="Имя"
          prop="name"
          :class="{ focused: isActiveInput === 'name' || form.name }"
      >
        <el-input
            v-model="form.name"
            @focus="isActiveInput = 'name'"
            @blur="isActiveInput = ''"
        />
      </el-form-item>
      <el-form-item
          label="Фамилия"
          prop="surname"
          :class="{ focused: isActiveInput === 'surname' || form.surname }"
      >
        <el-input
            v-model="form.surname"
            @focus="isActiveInput = 'surname'"
            @blur="isActiveInput = ''"
        />
      </el-form-item>
      <el-form-item
          label="Email"
          prop="email"
          :class="{ focused: isActiveInput === 'email' || form.email }"
      >
        <el-input
            v-model="form.email"
            @focus="isActiveInput = 'email'"
            @blur="isActiveInput = ''"
        />
      </el-form-item>
      <el-form-item
          label="Организация"
          prop="company"
          :class="{ focused: isActiveInput === 'company' || form.company }"
      >
        <el-input
            v-model="form.company"
            @focus="isActiveInput = 'company'"
            @blur="isActiveInput = ''"
        />
      </el-form-item>
      <el-form-item
          label="Должность"
          prop="position"
          :class="{ focused: isActiveInput === 'position' || form.position }"
      >
        <el-input
            v-model="form.position"
            @focus="isActiveInput = 'position'"
            @blur="isActiveInput = ''"
        />
      </el-form-item>
      <el-form-item
          label="Роль"
          prop="role"
          :class="{ focused: isActiveInput === 'role' || form.role }"
      >
        <el-select v-model="form.role" placeholder=" ">
          <el-option label="Администратор" value="admin" />
          <el-option label="Пользователь демо-доступа" value="demo" />
          <el-option label="Пользователь тарифа для учебных заведений" value="teaching" />
          <el-option label="Пользователь тарифа для организации" value="expert" />
        </el-select>
      </el-form-item>

      <div class="account__form__change_pass_btn" 
        v-if="changePass === null && $route.params.id"
        @click="changePass = true, $refs['userForm'].validate()" 
      >
        Изменить пароль
      </div>
      <el-form-item
          v-if="!$route.params.id || (changePass && $route.params.id)"
          label="Пароль"
          prop="password"
          :class="{ focused: isActiveInput === 'password' || form.password }"
      >
        <el-input
            v-model="form.password"
            type="password"
            @focus="isActiveInput = 'password'"
            @blur="isActiveInput = ''"
        />
      </el-form-item>
      <el-form-item
        v-if="!$route.params.id || (changePass && $route.params.id)"
        label="Повторите пароль"
        prop="password_confirmation"
        :class="{ focused: isActiveInput === 'password_confirmation' || form.password_confirmation }"
      >
        <el-input
          v-model="form.password_confirmation"
          type="password"
          @focus="isActiveInput = 'password_confirmation'"
          @blur="isActiveInput = ''"
        />
      </el-form-item>
      <div 
        v-if="changePass && $route.params.id" 
        class="account__form__change_pass_btn" 
        @click="changePass = null"
      >
        Отменить
      </div>
      
      <div class="account__form-button">
        <ButtonElement 
          text="Сохранить" 
          @click.prevent="saveUser('userForm')"
        />
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ButtonElement from '../elements/ButtonElement'
import { AlertNotify } from '@/mixin/alert_notify'

export default {
  name: 'User',
  mixins: [AlertNotify],
  components: {
    ButtonElement,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Введите пароль'));
      } else if(value.length < 6){
          callback(new Error('Пароль должен быть больше 6 символов'));
      }
      else {
        if (this.form.password_confirmation !== '') {
          this.$refs['userForm'].validateField('checkPass');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Повтрите пароль'));
      } else if (value !== this.form.password) {
        callback(new Error('Пароли не совпадают'));
      } else {
        callback();
      }
    };
    return {
      isActiveInput: '',
      changePass: null,
      form: {
        name: '',
        surname: '',
        email: '',
        company: '',
        position: '',
        role: '',
        password: '',
        password_confirmation: ''
      },
      rules: {
        name: [
          { required: true, message: 'Введите Имя', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Введите Email', trigger: 'blur' },
          { type: 'email', message: 'Введите  корректный Email', trigger: ['blur', 'change'] }
        ],
        password: [{required: true, validator: validatePass, trigger: 'blur' }],
        password_confirmation: [{required: true, validator: validatePass2, trigger: ['blur', 'change'] }],
      },
    }
  },
  created() {
    if(this.$route.params.id){
      for(let key in this.form){
        this.form[key] = this.user[key]
      }
    }
  },
  computed: {
    user(){
      return this.$store.state.Users.user
    },
  },
  methods: {
    ...mapActions('Users', [
        'getUsers'
    ]),
    ...mapActions('Users', [
        'getUser'
    ]),
    saveUser(form) {
      this.$refs[form].validate(valid => {
        if(valid) {
          const data = this.form
          if(this.$route.params.id && !this.changePass) {
            delete data.password
            delete data.password_confirmation
          }
          this.$store.dispatch(!this.$route.params.id ? 'Users/addUser' : 'Users/updateUser', { id:this.$route.params.id || null, data })
          .then(() => {
            if(this.$route.params.id){
              this.getUser(this.$route.params.id)
              this.successNotify('Данные обновлены')
            }else{ 
              this.getUsers(this.$route.query)
              this.successNotify('Пользователь добавлен')
            }
            this.$store.dispatch('Dialog/closeDialog')
          })
          .catch(err => {
            console.log(err.response)
          })
        }
      });
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

.account__form{
  width: 100%;
  max-width: 37rem;
  margin: 0 auto;
  padding: 30px 0;
  &-button{
    display: flex;
    justify-content: center;
    margin-top: 5rem;
  }
  &__change_pass_btn{
    text-align: center;
    cursor: pointer;
    color: #3278e1;
    margin-top:20px;
  }
}
</style>
