<template>
  <div class="set-north">
    <div class="set-north__title">
      Перед началом работы <br />
      укажите северное направление
    </div>
    <div class="set-north__actions">
      <ButtonElement text="Указать север" @click="$store.dispatch('Dialog/closeDialog')" />
    </div>
  </div>
</template>

<script>
import ButtonElement from '@/components/elements/ButtonElement'

export default {
  name: 'SetNorth',
  components: {
    ButtonElement,
  },
  data() {
    return {
      distance: null,
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

.set-north {
  padding: 1rem;

  &__title {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 4rem;
    color: $basicBlack;
  }
}
</style>
