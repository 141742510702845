import axios from 'axios'
const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
  projectsList: null,
  currentProjectGeneralData: null,
  currentProject: null,
  arrBuilding: [],
  arrWall: [],
  arrZone: [],
  arrEquipment: [],
  arrPipeEquipment: [],
  arrWorker: [],
  fireWall: null,
  currentBuildingPosition: null,
  currentWallPosition: null,
  currentPeoplePosition: null,
  currentWorkerPosition: null,
  currentMachinesPosition: null,
  arrLengthBranchEquipment: null,
  presenceOfWall: 'no',
  substance: null,
  potRisk: null,
  region: null,
  currentObject: null,
  currentWorkerPost: '',
  arrProbabilityOfPresenceWorker: [],
  arrWorkerPosNames: [],
  pipeDepressurization : [],
  pipeDepressurizationData: null,
  
  currentEditObject: null,
  accidents: null,
  selectedSwitcher: null,
  selectedSwitcherName: '',

  chartData: null,
  chartOptions: null,
  probabilityChartData: null,
  probabilityChartOptions: null,
  graphTableData: null,
  graphDestructionTableData: null,
  detailChartInfo: null,

  workerStop: false,
  currentGroupId: null,
})

const getters = {}

const mutations = {
  setData(state, data) {
    state[data.label] = data.data
  },
  pushData(state, data) {
    state[data.label].push(data.data)
  },

  addDataToProject(state, data) {
    state.currentProject[data.label] = data.data
  },

  addNewWall(state, data) {
    state.arrWall.push(data)
    if (state.currentProject.arrWall) state.currentProject.arrWall.push(data)
    else state.currentProject.arrWall = [data]
    state.currentWallPosition = null
  },
  editWall(state, data) {
    let editableObj = state.arrWall.find(el => el.id === data.id)
    let editableObjInproject = state.currentProject.arrWall.find(el => el.id === data.id)
    for(let key in data) {
      editableObj[key] = data[key]
      editableObjInproject[key] = data[key]
    }
    state.currentEditObject = null
  },
  
  addNewBuilding(state, data) {
    state.arrBuilding.push(data)
    if (state.currentProject.arrBuilding) state.currentProject.arrBuilding.push(data)
    else state.currentProject.arrBuilding = [data]
    state.currentBuildingPosition = null
  },
  editBuilding(state, data) {
    let editableObj = state.arrBuilding.find(el => el.id === data.id)
    let editableObjInproject = state.currentProject.arrBuilding.find(el => el.id === data.id)
    for(let key in data) {
      editableObj[key] = data[key]
      editableObjInproject[key] = data[key]
    }
    state.currentEditObject = null
  },

  addNewWorker(state, data) {
    state.arrWorker.push(data)
    if (state.currentProject.arrWorker) state.currentProject.arrWorker.push(data)
    else state.currentProject.arrWorker = [data]
    state.currentWorkerPosition = null
  },
  editWorker(state, data) {
    let editableObj = state.arrWorker.find(el => el.id === data.id)
    let editableObjInproject = state.currentProject.arrWorker.find(el => el.id === data.id)
    for(let key in data) {
      editableObj[key] = data[key]
      editableObjInproject[key] = data[key]
    }
    state.currentEditObject = null
  },

  addNewZone(state, data) {
    state.arrZone.push(data)
    if (state.currentProject.arrZone) state.currentProject.arrZone.push(data)
    else state.currentProject.arrZone = [data]
    state.currentPeoplePosition = null
  },
  editZone(state, data) {
    let editableObj = state.arrZone.find(el => el.id === data.id)
    let editableObjInproject = state.currentProject.arrZone.find(el => el.id === data.id)
    for(let key in data) {
      editableObj[key] = data[key]
      editableObjInproject[key] = data[key]
    }
    state.currentEditObject = null
  },

  addNewEquipment(state, data) {
    state.arrEquipment.push(data)
    if (state.currentProject.arrEquipment) state.currentProject.arrEquipment.push(data)
    else state.currentProject.arrEquipment = [data]
  },

  editEquipment(state, data) {
    let editableObj = state.arrEquipment.filter(el => el.groupId === data.groupId)
    let editableObjInproject = state.currentProject.arrEquipment.filter(el => el.groupId === data.groupId)

    editableObj.map((element,indx) => {
      for(let key in data) {
        element[key] = data[key]
        editableObjInproject[indx][key] = data[key]
      }
    })

    state.currentEditObject = null
    state.accidents = null
  },

  addNewPipeEquipment(state, data) {
    state.arrPipeEquipment.push(data)
    if (state.currentProject.arrPipeEquipment) state.currentProject.arrPipeEquipment.push(data)
    else state.currentProject.arrPipeEquipment = [data]
  },

  editPipeEquipment(state, data) {
    let editableObj = state.arrPipeEquipment.find(el => el.objDetailData.id === data.objDetailData.id)
    let editableObjInproject = state.currentProject.arrPipeEquipment.find(el => el.objDetailData.id === data.objDetailData.id)

    for(let key in data) {
      editableObj[key] = data[key]
      editableObjInproject[key] = data[key]
    }
    state.currentEditObject = null
    state.accidents = null
  },

  addDrawPoints(state, data){
    if(state.currentProject.drawPoints && state.currentProject.drawPoints[data.catName]){
      state.currentProject.drawPoints[data.catName] = [...state.currentProject.drawPoints[data.catName], data.data]
    } else {
      if(!state.currentProject.drawPoints) {
        state.currentProject.drawPoints = {}
      }
      state.currentProject.drawPoints[data.catName] = [data.data]
    }
  },
  addSubstancePoints(state, data){
    if(!state.currentProject.substances) {
      state.currentProject.substances = []
    }
    state.currentProject.substances.push(data)
  },
}

const actions = {
  getProjects({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/projects`,
        params,
        method: 'GET'
      })
        .then(resp => {
          commit('setData', { label: 'projectsList', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  createProjects(ctx, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/project`,
        data,
        method: 'POST'
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  changeProjects(ctx, { data, id }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/project/${id}`,
        data,
        method: 'POST'
      })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  getProject({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiUrl}/project/${id}`,
        method: 'GET'
      })
        .then(resp => {
          let data = {}

          for(let key in resp.data.data) {
            if (key === 'settings') {
              if(typeof resp.data.data[key] === "string") {
                data[key] = JSON.parse(resp.data.data[key])
              } else {
                data[key] = resp.data.data[key]
              }
            } else {
              data[key] = resp.data.data[key]
            }
          }

          commit('setData', { label: 'currentProjectGeneralData', data })
          if (resp.data.data.settings) {
            let settings = null

            if(typeof resp.data.data.settings === "string"  ) {
              settings = JSON.parse(resp.data.data.settings)
            } else {
              settings = resp.data.data.settings
            }
            
            commit('setData', { label: 'currentProject', data: settings })
            if (settings.arrEquipment) commit('setData', { label: 'arrEquipment', data: JSON.parse(JSON.stringify(settings.arrEquipment)) })
            if (settings.arrPipeEquipment) commit('setData', { label: 'arrPipeEquipment', data: JSON.parse(JSON.stringify(settings.arrPipeEquipment)) })
            if (settings.arrBuilding) commit('setData', { label: 'arrBuilding', data: JSON.parse(JSON.stringify(settings.arrBuilding)) })
            if (settings.arrWall) commit('setData', { label: 'arrWall', data: JSON.parse(JSON.stringify(settings.arrWall)) })
            if (settings.arrWorker) commit('setData', { label: 'arrWorker', data: JSON.parse(JSON.stringify(settings.arrWorker)) })
            if (settings.arrZone) commit('setData', { label: 'arrZone', data: JSON.parse(JSON.stringify(settings.arrZone)) })
          }
          resolve(resp)
        })
        .catch(err => {
          console.log(err.response)
          reject(err)
        })
    })
  },
  delProject (ctx , id) {
    return new Promise((resolve, reject) => {
      axios({ url: `${apiUrl}/project/${id}`, method: 'DELETE' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
