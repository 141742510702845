import axios from 'axios'
const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
  products: [],
  changinProduct: null,
  changinProductId: null,
})

const getters = {}
const mutations = {
  setData(state, data) {
    state[data.label] = data.data
  },
}
const actions = {

  getProducts ({ commit }) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/products', method: 'GET' })
        .then(resp => {
          commit('setData', {label:"products", data: resp.data.data})
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  buyProduct (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/product/buy', data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  editProduct ({ dispatch, commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/admin/product/${state.changinProductId}`, data, method: 'PUT' })
        .then(resp => {
          resolve(resp)
          commit('setData', { label: 'changinProductId', data: null })
          commit('setData', { label: 'changinProduct', data: null })
          dispatch('getProducts')
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
