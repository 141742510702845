import axios from 'axios'
const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
  user: null,
})
const getters = {}
const mutations = {
  setData(state, data) {
    state[data.label] = data.data
  },
  
}
const actions = {
  getUser ({ commit }) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/user', method: 'GET' })
        .then(resp => {
          commit('setData', {label:"user", data: resp.data.data})
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateUser (ctx , data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/user', data, method: 'PUT' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
