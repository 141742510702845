<template>
  <div class="confirm">
    <div class="confirm__title">
       Подтвердите правильность выбора северного направления
    </div>
    <div class="confirm__actions">
      <ButtonElement
        text="Отмена"
        @click="handleClick('cancel')"
      />
      <ButtonElement
        text="Применить"
        @click="handleClick('confirm')"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from '@/components/elements/ButtonElement'

export default {
  name: 'Confirm',
  components: {
    ButtonElement,
  },
  methods: {
    handleClick(value) {
      this.$store.commit('Dialog/setData', { label: 'confirmAnswer', value })
      this.$store.dispatch('Dialog/closeDialog')
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

.confirm {
  &__title {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $basicBlack;
  }

  &__actions {
    display: flex;
    margin-top: 2rem;
  }
}
</style>
