<template>
  <div v-if="changinProduct" class="add-building">
    <div class="add-building__row">
      <div class="add-building__label">
        Цена
      </div>
      <el-input v-model="price" />
    </div>

    <div class="add-building__row">
      <div class="add-building__label">
        Старая цена
      </div>
      <el-input v-model="old_price" />
    </div>

    <div class="add-building__row checkbox-row">
      <div class="add-building__label">
        Оптимальный выбор
      </div>
      <el-checkbox v-model="is_optimally" />
    </div>

    <div class="add-building__buttons">
      <ButtonElement text="Отмена" @click="cancel"/>
      <ButtonElement
        text="Сохранить"
        :disabled="!price"
        @click="editProduct()"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from '../elements/ButtonElement'
import { AlertNotify } from '@/mixin/alert_notify'

export default {
  name: 'AddBuilding',
  mixins: [AlertNotify],
  components: {
    ButtonElement,
  },

  data() {
    return {
      price: '',
      old_price: '',
      is_optimally: false,
    }
  },

  computed: {
    changinProduct() {
      return this.$store.state.Products.changinProduct
    }
  },

  created() {
    for(const key in this.changinProduct) {
      if(key === 'is_optimally' && this.changinProduct['is_optimally']) {
        this.is_optimally = true
      } else {
        this[key] = this.changinProduct[key]
      }
    }
  },

  methods: {
    editProduct() {
      if(this.price) {
        let obj = {
          price: this.price,
          is_optimally: this.is_optimally,
        }

        if(this.old_price) {
          obj.old_price = this.old_price
        } else {
          obj.old_price = null
        }
        
        this.$store.dispatch('Products/editProduct', obj)
        this.$store.dispatch('Dialog/closeDialog')
      } else {
        this.errorNotify('Заполните обязательные поле цена')
      }
    },

    cancel() {
      this.$store.commit('Project/setData', { label: 'currentEditObject', data: null })
      this.$store.dispatch('Dialog/closeDialog')
      this.$store.commit('Dialog/setData', { label: 'redrawCanvasAfterCloseDialog', data: true })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

.add-building {
  &__row {
    border-bottom: 1px solid $lightGrey;
    padding: 2rem 3rem;

    &.checkbox-row {
      display: flex;
      align-items: center;

      .add-building__label {
        margin-bottom: 0;
      }
    }
  }

  &__label {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $basicBlack;
    margin-bottom: 0.8rem;
    margin-right: 1rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    padding: 4rem 0;
  }
  
}
</style>
