const state = () => ({
  failureParameterObj: [],
  useLoader: false,
})

const getters = {}

const mutations = {
  setData(state, data) {
    state[data.label] = data.data
  },
  setTreesPhoto(state, data) {
    state.failureParameterObj[data.index].treesPhoto = data.data
  },
  setParameters(state, data) {
    if (state[data.label].length) {
      state[data.label].splice(data.index, 1, data.parameters)
    } else {
      state[data.label].push(data.parameters)
    }
  },
  setTreesChanges(state, data) {
    if(state[data.label].length){
      state[data.label][data.index].trees = data.parameters
    }
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
