<template>
  <el-dialog 
    v-model="dialogVisible" 
    :title="titleDialog" 
    :show-close="$store.state.Dialog.showClose" 
    @closed="$store.commit('Dialog/setData', { label: 'showClose', data: true })"
  >
    <component :is="activeDialog" />
  </el-dialog>
</template>

<script>
import NewProject from './NewProject'
import CreateEquipment from './CreateEquipment'
import CreatePipe from './CreatePipe'
import AddBuilding from './AddBuilding'
import AddWall from './AddWall'
import AddWorker from './AddWorker'
import AddPeople from './AddPeople'
import User from './User'
import Tariff from './Tariff'
import Charts from './Charts'
import ChangeTariff from './ChangeTariff'

export default {
  name: 'Base',
  components: {
    NewProject,
    CreateEquipment,
    CreatePipe,
    AddBuilding,
    AddWall,
    AddWorker,
    AddPeople,
    User,
    Tariff,
    Charts,
    ChangeTariff
  },
  computed: {
    dialogVisible: {
      get() {
        return this.$store.state.Dialog.dialogVisible
      },
      set(val) {
        if (!val) this.$store.commit('Dialog/setData', { label: 'activeDialog', data: '' })
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: val })
      },
    },
    activeDialog() {
      return this.$store.state.Dialog.activeDialog
    },
    titleDialog() {
      return this.$store.state.Dialog.titleDialog
    },
  },
  watch: {
    $route (){
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: null })
    },
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

.el-overlay-dialog {
  overflow: auto;
}

.el-dialog {
  box-shadow: 0 6px 64px rgba(0, 0, 0, 0.15);
  border-radius: 1.2rem;

  .el-dialog__headerbtn {
    top: 2.7rem;

    .el-dialog__close {
      font-size: 3rem;
      color: $basicWhite;
    }
  }

  &__header {
    background: $lightBlack;
    padding: 2.7rem 3rem;
    border-radius: 1.2rem 1.2rem 0 0;
  }

  &__title {
    color: $basicWhite;
    font-weight: 600;
    font-size: 2.6rem;
    line-height: 3.6rem;
  }

  &__body {
    padding: 0;
  }
}
</style>
