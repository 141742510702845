export const AlertNotify = {
  methods: {
    errorNotify(errors){
      if(typeof errors === "string"){
        this.$notify.error({
          message: errors || 'Ошибка',
          showClose: false,
          duration: 3000
        });
      }else{
        for(let key in errors){
          this.$notify.error({
            message: errors[key][0] || 'Ошибка',
            showClose: false,
            duration: 3000
          });
        }
      }

    },
    successNotify(successTitle){
      this.$notify.success({
        title: successTitle || 'Успех',
        showClose: false,
        duration: 2000
      });
    }
  },
};
