<template>
  <component :is="layout">
    <router-view />
  </component>
  <Base />
  <NotificationsBase />
</template>

<script>
import Base from './components/dialog/Base'
import NotificationsBase from './components/dialog/NotificationsBase'

export default {
  components: {
    Base,
    NotificationsBase,
  },
  computed: {
    layout() {
      return this.$route.meta.layout
    },
  },
}
</script>

<style lang="scss"></style>
