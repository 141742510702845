<template>
  <div class="auth">
    <div class="auth__logo">
      <img src="../assets/images/logo.svg" alt="">
    </div>
    <div class="auth__content">
      <slot />
    </div>
    <div class="auth__footer">
      <div class="auth__footer-links">
        <a href="/probit-user-agreement.pdf" target="_blank">
          Пользовательское соглашение
        </a>
        <a href="/probit-privacy-policy.pdf" target="_blank">
          Политика конфиденциальности
        </a>
      </div>
      <div class="auth__footer-copy">
        © PROBIT. Все права защищены
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.auth {
  background: url("../assets/images/auth-background.jpg") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 4rem 0;

  &__logo {
    width: 19rem;
  }

  &__content {
    margin: 7rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $basicWhite;
    font-size: 1.2rem;
    line-height: 1.6rem;

    &-links {
      margin-bottom: 2rem;

      @include below($md) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      a {
        font-weight: 700;
        margin-right: 4rem;

        @include below($md) {
          margin-right: 0;
          display: block;
        }

        &:hover {
          text-decoration: underline;
        }

        &:last-of-type {
          margin-right: 0;

          @include below($md) {
            margin-top: 2rem;
          }
        }
      }
    }
  }
}
</style>
