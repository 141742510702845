<template>
  <div class="city__form">
    <el-form
      :model="form"
      ref="cityForm"
      :show-message="false"
      :rules="rules"
    >
      <div class="city__form-row">
        <div class="city__form-label">
          Название города
        </div>
        <el-form-item
          prop="name"
        >
          <div class="city__form-input">
            <el-input
              v-model="form.name"
              @focus="isActiveInput = 'name'"
              @blur="isActiveInput = ''"
            />
          </div>
        </el-form-item>
      </div>
      <div class="city__form-text">
        Климатические условия города расположения производственного объекта
      </div>
      <div class="city__form-row">
        <div class="city__form-label">
          Абсолютная максимальная температура воздуха в городе расположения предприятия, К
        </div>
        <div class="city__form-input">
          <el-input-number
            v-model="form.absoluteMaxTemperature"
            controls-position="right"
          />
        </div>
      </div>
      <div class="city__form-row">
        <div class="city__form-label">
          Средняя максимальная температура воздуха наиболее теплого месяца в городе расположения предприятия, К
        </div>
        <div class="city__form-input">
          <el-input-number
            v-model="form.averageMaxTemperature"
            controls-position="right"
          />
        </div>
      </div>
      <div class="city__form-text-small">
        Средние скорости ветра по направлениям в июле в городе расположения предприятия, м/c:
      </div>
      <div class="city__form-row">
        <div class="city__form-label">
          Северный:
        </div>
        <div class="city__form-input">
          <el-input-number
            v-model="form.northWindVelocity"
            controls-position="right"
            :min="0"
            :max="10"
          />
        </div>
      </div>
      <div class="city__form-row">
        <div class="city__form-label">
          Северo-восточный:
        </div>
        <div class="city__form-input">
          <el-input-number
            v-model="form.northEastWindVelocity"
            controls-position="right"
            :min="0"
            :max="10"
          />
        </div>
      </div>
      <div class="city__form-row">
        <div class="city__form-label">
          Восточный:
        </div>
        <div class="city__form-input">
          <el-input-number
            v-model="form.eastWindVelocity"
            controls-position="right"
            :min="0"
            :max="10"
          />
        </div>
      </div>
      <div class="city__form-row">
        <div class="city__form-label">
          Юго-восточный:
        </div>
        <div class="city__form-input">
          <el-input-number
            v-model="form.southEastWindVelocity"
            controls-position="right"
            :min="0"
            :max="10"
          />
        </div>
      </div>
      <div class="city__form-row">
        <div class="city__form-label">
          Южный:
        </div>
        <div class="city__form-input">
          <el-input-number
            v-model="form.southWindVelocity"
            controls-position="right"
            :min="0"
            :max="10"
          />
        </div>
      </div>
      <div class="city__form-row">
        <div class="city__form-label">
          Юго-западный:
        </div>
        <div class="city__form-input">
          <el-input-number
            v-model="form.southWestWindVelocity"
            controls-position="right"
            :min="0"
            :max="10"
          />
        </div>
      </div>
      <div class="city__form-row">
        <div class="city__form-label">
          Западный:
        </div>
        <div class="city__form-input">
          <el-input-number
            v-model="form.westWindVelocity"
            controls-position="right"
            :min="0"
            :max="10"
          />
        </div>
      </div>
      <div class="city__form-row">
        <div class="city__form-label">
          Северо-западный:
        </div>
        <div class="city__form-input">
          <el-input-number
            v-model="form.northWestWindVelocity"
            controls-position="right"
            :min="0"
            :max="10"
          />
        </div>
      </div>
      <div class="city__form-row">
        <div class="city__form-label">
          Повторяемость штилей, доли:
        </div>
        <div class="city__form-input">
          <el-input-number
            v-model="form.calm"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div class="city__form-button">
        <ButtonElement
          :text="this.$route.params.id  === 'new' ? 'Создать' : 'Сохранить'"
          @click.prevent="cityAction('cityForm')"
        />
        <el-popconfirm 
          v-if="this.$route.params.id && this.$route.params.id !== 'new'" 
          title="Удалить город?" 
          cancelButtonText="Отмена"
          confirmButtonText="Да"
          @confirm="dellCity"
        >
          <template #reference>
            <ButtonElement
              text="Удалить"
              @click.prevent
            />
          </template>
        </el-popconfirm>

      </div>
    </el-form>
  </div>
</template>

<script>
import ButtonElement from '../components/elements/ButtonElement';
import { AlertNotify } from '@/mixin/alert_notify'

export default {
  name: 'AddNewCity',
  mixins: [AlertNotify],
  components: {
    ButtonElement
  },
  data() {
    return {
      isActiveInput: '',
      form: {
        name: '',
        absoluteMaxTemperature: 0,
        averageMaxTemperature: 0,
        northWindVelocity: 0,
        northEastWindVelocity: 0,
        eastWindVelocity: 0,
        southEastWindVelocity: 0,
        southWindVelocity: 0,
        southWestWindVelocity: 0,
        westWindVelocity: 0,
        northWestWindVelocity: 0,
        calm: 0,
      },
      rules: {
        name: [
          { required: true, trigger: 'blur' },
        ],
      }
    }
  },
  mounted() {
    if(this.$route.params.id !== 'new' && !this.$store.state.Dialog.dialogVisible) {
      this.$store.dispatch('Cities/getCity', this.$route.params.id)
        .then(res => {
          this.form.name = res.data.data.name
          for(let key in res.data.data.settings) {
            this.form[key] = res.data.data.settings[key]
          }
        })
        .catch(err => {
          console.log(err.response.data.errors)
        })
    }
  },
  methods: {
    cityAction(form) {
      this.$refs[form].validate(valid => {
        if(valid) {
          const data = { name: this.form.name, settings:{} }
          for(let key in this.form) {
            if(key !== 'name') {
              data.settings[key] = this.form[key]
            }
          }
          if(!this.$store.state.Dialog.dialogVisible){
            this.$store.dispatch(this.$route.params.id === 'new' ? 'Cities/addAdminCity' : 'Cities/changeAdminCity',
            { id:this.$route.params.id, data })
              .then(() => {
                if(this.$route.params.id){
                  this.successNotify('Данные обновлены')
                }
                this.$router.push('/cities') 
              })
              .catch(err => {
                console.log(err.response.data.errors)
              })
          } else {
            this.$store.dispatch('Cities/addCityToUser', { data })
              .then(() => {
                this.$store.dispatch('Cities/getCitiesForUser')
              })
              .catch(err => {
                console.log(err.response.data.errors)
              })
          }
        }
      })
    },
    dellCity() {
      this.$store.dispatch('Cities/dellCity', this.$route.params.id)
        .then(() => {
            this.$router.push('/cities')
          })
          .catch(err => {
            console.log(err.response.data.errors)
          })
    }
  },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.city__form {
  &-button {
    margin-top: 6rem;
    display: flex;
    justify-content: center;
  }

  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $lightGrey;
    padding: 2rem 3rem;
  }

  &-label {
    font-size: 1.4rem;
    line-height: 1.9rem;
    width: calc(100% - 26rem);
    word-break: break-word;
  }

  &-input {
    width: 24rem;
  }

  &-text {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.5rem;
    margin: 3rem 0;
    padding: 0 3rem;

    &-small {
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.9rem;
      margin: 3rem 0 0;
      padding: 0 3rem;
    }
  }
}
</style>
