<template>
  <div class="equipment">
    <div class="equipment__title">
      {{ stepTitle }}
    </div>
    <div class="equipment__steps">
      <span
        v-for="i in 3"
        :key="i"
        :class="[{ current: i === currentStep, previous: i < currentStep }]"
      />
    </div>
    <div v-if="currentStep === 1" class="equipment__step">
      <div class="equipment__row">
        <div class="equipment__row-label">
          Класс технологического оборудования
        </div>
        <div class="equipment__row-input">
          <el-select v-model="form.equipmentClass">
            <el-option
              v-for="item in equipmentClasses"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="equipment__row">
        <div class="equipment__row-label">
          Агрегатное состояние вещества
        </div>
        <div class="equipment__row-input">
          <el-select v-model="form.stateOfAggregation">
            <el-option
              v-for="(state, idx) in statesOfAggregation"
              :key="idx"
              :label="state.label"
              :value="state.value"
            />
          </el-select>
        </div>
      </div>
      <div class="equipment__row">
        <div class="equipment__row-label">
          Наименование вещества
        </div>
        <div class="equipment__row-input">
          <el-select 
            v-model="form.substance"
            filterable
            remote
            reserve-keyword
          >
            <el-option
              v-for="item in substances"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div v-if="form.substance === 'newSubstance'" class="equipment__title equipment__title--mg">
        Физико-химические и пожаровзрывоопасные свойства вещества
      </div>
      <AddNewSubstance v-if="form.substance === 'newSubstance'" :typeSubstance="form.stateOfAggregation" />
    </div>
    <div v-if="currentStep === 2" class="equipment__step">
      <div class="equipment__row">
        <div class="equipment__row-label">
          Название оборудования 
        </div>
        <div class="equipment__row-input">
          <el-input v-model="form.name" />
        </div>
      </div>
      <div class="equipment__row">
        <div class="equipment__row-label">
          Описание оборудования <span class="required-field">*</span>
        </div>
        <div class="equipment__row-input">
          <el-input v-model="form.description" :rows="3" type="textarea" />
        </div>
      </div>
      
      <div v-if="(form.equipmentClass === 'tank' || form.equipmentClass === 'pump') && form.stateOfAggregation === 'liquid'" class="equipment__row">
        <div class="equipment__row-label">
          Расположение оборудования
        </div>
        <div class="equipment__row-input">
          <el-select v-model="form.equipmentLocation">
            <el-option label="Наземное" value="ground" />
            <el-option label="Подземное" value="underground" />
          </el-select>
        </div>
      </div>

      <div v-if="form.equipmentClass === 'tank'" class="equipment__row">
        <div class="equipment__row-label">
          Объем емкостного оборудования, м3
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.volumeTank"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>

      <div v-if="form.equipmentClass === 'tank' && form.stateOfAggregation !== 'compressedGas'" class="equipment__row">
        <div class="equipment__row-label">
          Степень заполнения емкостного оборудования
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.fillRatioTank"
            controls-position="right"
            :max="1"
            :min="0"
          />
        </div>
      </div>

      <div v-if="form.equipmentClass === 'tank' && form.stateOfAggregation === 'liquid'" class="equipment__row">
        <div class="equipment__row-label">
          Высота уровня взлива жидкости в емкостном оборудовании, м
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.liquidHeightTank"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>

      <div v-if="form.equipmentClass === 'tank' && form.stateOfAggregation === 'liquid'" class="equipment__row">
        <div class="equipment__row-label">
          Избыточное давление в емкостном оборудовании, Па
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.overpressureTank"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>

      <div v-if="form.equipmentClass === 'tank' && form.stateOfAggregation !== 'liquefiedGasVapor'" class="equipment__row">
        <div class="equipment__row-label">
          Избыточное давление срабатывания предохранительного клапана или мембраны, кПа
          (критическое избыточное давление разгерметизации - для герметичных аппаратов при отсутствии предохранительных устройств)
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.valvePressure"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      
      <div v-if="form.equipmentClass === 'pump' && form.stateOfAggregation !== 'liquefiedGasFluid'" class="equipment__row">
        <div class="equipment__row-label">
          Напор, создаваемый насосом, м
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.pumpHead"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>

      <div v-if="form.equipmentClass === 'pump' && form.stateOfAggregation === 'liquid'" class="equipment__row">
        <div class="equipment__row-label">
          Производительность (объемный расход) насоса, м3/c
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.pumpVolumeFlowRate"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>

      <div v-if="form.equipmentClass === 'pump' && form.stateOfAggregation !== 'liquid'" class="equipment__row">
        <div class="equipment__row-label">
          Производительность (массовый расход) насоса, кг/c
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.pumpMassFlowRate"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>

      <div v-if="form.equipmentClass === 'compressor'" class="equipment__row">
        <div class="equipment__row-label">
          Производительность (массовый расход) компрессора, кг/c
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.compressorMassFlowRate"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>

      <div v-if="form.stateOfAggregation !== 'liquid' " class="equipment__row">
        <div class="equipment__row-label">
          Рабочее давление, Па
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.operatingPressure"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      
      <div v-if="form.stateOfAggregation !== 'liquid'" class="equipment__row">
        <div class="equipment__row-label">
          Рабочая температура, К
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.operatingTemperature"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>

      <div v-if="form.equipmentClass === 'compressor' || form.equipmentClass === 'pump'" class="equipment__row">
        <div class="equipment__row-label">
          Диаметр трубопровода между отключающими устройствами, мм
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.cutoffPipeDiameter"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>

      <div v-if="form.equipmentClass === 'compressor' || form.equipmentClass === 'pump'" class="equipment__row">
        <div class="equipment__row-label">
          Длина трубопровода между отключающими устройствами, м
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.cutoffPipeLength"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>

      <div v-if="form.equipmentClass === 'compressor' || form.equipmentClass === 'pump'" class="equipment__row">
        <div class="equipment__row-label">
          Расчетное время отключения трубопровода, с
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.cutoffTime"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>

      <div v-if="form.stateOfAggregation === 'liquid' || form.stateOfAggregation === 'liquefiedGasFluid'" class="equipment__row">
        <div class="equipment__row-label">
          Подстилающая поверхность
        </div>
        <div class="equipment__row-input">
          <el-select v-model="form.surfaceProperty" @change="returnSurface">
            <el-option label="Бетонное или асфальтовое покрытие" value="concrete" />
            <el-option label="Спланированная грунтовая поверхность" value="plannedGround" />
            <el-option label="Неспланированная грунтовая поверхность" value="unplannedGround" />
            <el-option label="Другая поверхность" value="newSurface" />
          </el-select>
        </div>
      </div>

      <div v-if="form.surfaceProperty === 'newSurface'" class="equipment__title">
        Характеристики поверхности
      </div>
      <div v-if="form.surfaceProperty === 'newSurface'">
        <div class="equipment__row">
          <div class="equipment__row-label">
            Коэффициент разлития, м-1
          </div>
          <div class="equipment__row-input">
            <el-input-number
              v-model="form.surfaceSpreadCoefficient"
              controls-position="right"
              :min="0"
            />
          </div>
        </div>
        <div class="equipment__row">
          <div class="equipment__row-label">
            Коэффициент теплопроводности, Вт/(м*К)
          </div>
          <div class="equipment__row-input">
            <el-input-number
              v-model="form.surfaceThermalConductivity"
              controls-position="right"
              :min="0"
            />
          </div>
        </div>
        <div class="equipment__row">
          <div class="equipment__row-label">
            Удельная теплоемкость, Дж/(кг*К)
          </div>
          <div class="equipment__row-input">
            <el-input-number
              v-model="form.surfaceSpecificHeat"
              controls-position="right"
              :min="0"
            />
          </div>
        </div>
        <div class="equipment__row">
          <div class="equipment__row-label">
            Плотность, кг/м3
          </div>
          <div class="equipment__row-input">
            <el-input-number
              v-model="form.surfaceDensity"
              controls-position="right"
              :min="0"
            />
          </div>
        </div>
      </div>
      <div v-if="form.stateOfAggregation === 'liquid' || form.stateOfAggregation === 'liquefiedGasFluid'" class="equipment__row">
        <el-checkbox v-model="form.palletAvailability" label="Наличие поддона (обвалование, ограждающая стена, бортики и т.п.)" />
      </div>
      <div v-if="(form.stateOfAggregation === 'liquid' || form.stateOfAggregation === 'liquefiedGasFluid') && form.palletAvailability" class="equipment__row">
        <el-checkbox v-model="form.efficiencyPalletAtHole" label="Поддон ограничивает пролив при локальном повреждении оборудования" />
      </div>
      <div v-if="(form.stateOfAggregation === 'liquid' || form.stateOfAggregation === 'liquefiedGasFluid') && form.efficiencyPalletAtHole" class="equipment__row">
        <div class="equipment__row-label">
          Площадь поддона за вычетом площади размещенного в нем оборудования, м2
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.palletArea"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div v-if="(form.stateOfAggregation === 'liquid' || form.stateOfAggregation === 'liquefiedGasFluid') && form.palletAvailability" class="equipment__row">
        <el-checkbox v-model="form.efficiencyPalletAtDestruction" label="Поддон ограничивает пролив при полном разрушении оборудования" />
      </div>
      <div v-if="(form.stateOfAggregation === 'liquid' || form.stateOfAggregation === 'liquefiedGasFluid') && form.efficiencyPalletAtDestruction" class="equipment__row">
        <div class="equipment__row-label">
          Площадь поддона за вычетом площади размещенного в нем оставшегося неразрушенным оборудования, м2
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.palletAreaAtDestruction"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div class="equipment__row">
        <el-checkbox v-model="form.liquidationHoleEmergencyServices" label="Аварийно-спасательные формирования ликвидируют возгорание при мгновенном воспламенении вещества,
          вышедшего из оборудования в результате его локального повреждения (в том числе полного разрушения трубопровода),
          и герметизируют место утечки" />
      </div>
      <div v-if="form.liquidationHoleEmergencyServices" class="equipment__row">
        <div class="equipment__row-label">
          Продолжительность ликвидации возгорания
          и герметизации отверстия аварийно-спасательными формированиями, с
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.timeLiquidationHole"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div v-if="form.stateOfAggregation === 'liquid' || form.stateOfAggregation === 'liquefiedGasFluid'" class="equipment__row">
        <el-checkbox v-model="form.liquidationSpillEmergencyServices" label="Аварийно-спасательные формирования ликвидируют пролив вещества, покрывая его пеной или перекачивая в аварийный резервуар" />
      </div>
      <div v-if="(form.stateOfAggregation === 'liquid' || form.stateOfAggregation === 'liquefiedGasFluid') && form.liquidationSpillEmergencyServices" class="equipment__row">
        <div class="equipment__row-label">
          Продолжительность ликвидации пролива аварийно-спасательными формированиями, с
        </div>
        <div class="equipment__row-input">
          <el-input-number
            v-model="form.timeLiquidationSpill"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div class="equipment__row">
        <div class="equipment__row-label">
          Характеристика взрыва топливовоздушной смеси в открытом пространстве
        </div>
        <div class="equipment__row-input">
          <el-select v-model="form.explosionInOpenSpace">
            <el-option label="Взрыв поверхностный - да" value="surfaceExplosionYes" />
            <el-option label="Взрыв поверхностный - нет" value="surfaceExplosionNo" />
          </el-select>
        </div>
      </div>
    </div>
    <div v-if="currentStep === 3" class="equipment__step">
      <Accidents
        :equipment-class="form.equipmentClass"
        :state-of-aggregation="form.stateOfAggregation"
        :equipment="form"
      />
      <div class="tree-notification">Для сохранения изменений, сделаных в деревьях, нажмите на "Рассчитать деревья"</div>
    </div>
    <div  class="equipment__buttons">
      <ButtonElement text="Отмена" @click="cancel"/>
      <div v-if="form.substance !== 'newSubstance'" class="btn-container">
        <ButtonElement v-if="!editData" :text="currentStep === 3 ? 'Создать оборудование' : 'Далее'" @click="nextStep" />
        <ButtonElement v-else :text="currentStep === 3 ? 'Сохранить' : 'Далее'" @click="nextStep" />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from '../elements/ButtonElement'
import Accidents from '../Accidents'
import AddNewSubstance from '../AddNewSubstance'
import { AlertNotify } from '@/mixin/alert_notify'
import { mapState } from 'vuex'

export default {
  name: 'CreateEquipment',
  mixins: [AlertNotify],
  components: {
    ButtonElement,
    Accidents,
    AddNewSubstance,
  },
  data() {
    return {
      currentStep: 1,
      saveEditMode: false,
      form: {
        equipmentClass: 'tank',
        stateOfAggregation: 'liquid',
        substance: "gasoline",
        name: '',
        description: '',
        equipmentLocation: 'ground',
        volumeTank: undefined,
        fillRatioTank: 1,
        liquidHeightTank: undefined,
        overpressureTank: undefined,
        valvePressure: undefined,
        deltaLiquidHeight: undefined,
        pumpHead: undefined,
        pumpVolumeFlowRate: undefined,
        pumpMassFlowRate: undefined,
        compressorMassFlowRate: undefined,
        operatingPressure: undefined,
        operatingTemperature: undefined,
        cutoffPipeDiameter: undefined,
        cutoffPipeLength: undefined,
        cutoffTime: undefined,
        palletAvailability: false,
        efficiencyPalletAtDestruction: false,
        efficiencyPalletAtHole: false,
        palletArea: undefined,
        palletAreaAtDestruction: undefined,
        liquidationHoleEmergencyServices: false,
        timeLiquidationHole: undefined,
        liquidationSpillEmergencyServices: false,
        timeLiquidationSpill: undefined,
        explosionInOpenSpace: 'surfaceExplosionYes',
        diameterHole: undefined,
        diameterTank: undefined,
        failure: undefined,
        massSubstanceInEquipment: undefined,
        massSubstanceRate: undefined,
        actualSpillArea: undefined,
        massSubstanceInCloud: undefined,
        surfaceProperty: 'concrete',
        surfaceSpreadCoefficient: 150,
        surfaceThermalConductivity: 1.5,
        surfaceSpecificHeat: 1000,
        surfaceDensity: 2200,
      },

      materials: null,
      groupId: null,

      equipmentClasses: [
        {
          label: 'Емкостное оборудование',
          value: 'tank'
        },
        {
          label: 'Насос',
          value: 'pump'
        },
        {
          label: 'Компрессор',
          value: 'compressor'
        },
      ],
    }
  },
  created() {
    if(this.editData) {
      for(let key in this.editData.objDetailData.baseData) {
        this.form[key] = this.editData.objDetailData.baseData[key]
      }
      this.id = this.editData.objDetailData.id
      this.groupId = this.editData.groupId
    }
    this.getSubstances()
  },
  computed: {
    ...mapState('Project', [
      'currentProject','currentGroupId',
    ]),

    test() {
      if (this.form.liquidationSpillEmergencyServices) {
       return ['one', 'two', 'three']
      } else {
        return ['one', 'two']
      }
    },

    editData() {
      return JSON.parse(JSON.stringify(this.$store.state.Project.currentEditObject))
    },
    stepTitle() {
      if (this.currentStep === 3) {
        return 'Прогнозирование сценариев развития пожароопасной ситуации'
      } else if ( this.currentStep === 2) {
        return 'Характеристики технологического оборудования'
      } else {
        return 'Класс технологического оборудования и свойства горючего вещества'
      }
    },
    statesOfAggregation() {
      let arrStates = []
      if (this.form.equipmentClass === 'compressor') {
        arrStates = [
          { label: 'Сжатый газ', value: 'compressedGas' },
          { label: 'Паровая фаза сжиженного газа', value: 'liquefiedGasVapor' }
        ]
      } else if (this.form.equipmentClass === 'pump') {
        arrStates = [
          { label: 'Жидкость', value: 'liquid' },
          { label: 'Жидкая фаза сжиженного газа', value: 'liquefiedGasFluid' },
        ]
      } else {
        arrStates = [
          { label: 'Жидкость', value: 'liquid' },
          { label: 'Сжатый газ', value: 'compressedGas' },
          { label: 'Жидкая фаза сжиженного газа', value: 'liquefiedGasFluid' },
          { label: 'Паровая фаза сжиженного газа', value: 'liquefiedGasVapor' }
        ]
      }
      return arrStates
    },
    substances() {
      const arr = []
      const substances = this.$store.state.Project.currentProject.substances
      
      if(this.materials){
        const materials = this.materials
        if (this.stateOfAggregation === 'liquid') {
          arr.push({ label: 'Бензин', value: 'gasoline'})
          materials.map((element) => {
            if(element.state_of_aggr === 'liquid') {
              arr.push({ label: element.name, value: element.id})
            }
          })
        } else if (this.stateOfAggregation === 'compressedGas') {
          arr.push({ label: 'Метан сжатый', value: 'methaneCompressed' })
          materials.map((element) => {
            if(element.state_of_aggr === 'compressedGas') {
              arr.push({ label: element.name, value: element.id})
            }
          })
        } else if(this.stateOfAggregation === 'liquefiedGasFluid') {
          arr.push({ label: 'Пропан сжиженный', value: 'propaneLiquefied' })
          materials.map((element) => {
            if(element.state_of_aggr === 'liquefiedGasFluid') {
              arr.push({ label: element.name, value: element.id})
            }
          })
        } else if(this.stateOfAggregation === 'liquefiedGasVapor'){
          arr.push({ label: 'Пропан сжиженный', value: 'propaneLiquefied' })
          materials.map((element) => {
            if(element.state_of_aggr === 'liquefiedGasVapor') {
              arr.push({ label: element.name, value: element.id})
            }
          })
        }
      }
      
      if(substances) {
        if (this.stateOfAggregation === 'liquid') {
          substances.map((element) => {
            if(element.state_of_aggr === 'liquid') {
              arr.push({ label: element.name, value: element.id})
            }
          })
        } else if (this.stateOfAggregation === 'compressedGas') {
          substances.map((element) => {
            if(element.state_of_aggr === 'compressedGas') {
              arr.push({ label: element.name, value: element.id})
            }
          })
        } else if(this.stateOfAggregation === 'liquefiedGasFluid') {
          substances.map((element) => {
            if(element.state_of_aggr === 'liquefiedGasFluid') {
              arr.push({ label: element.name, value: element.id})
            }
          })
        } else if(this.stateOfAggregation === 'liquefiedGasVapor'){
          substances.map((element) => {
            if(element.state_of_aggr === 'liquefiedGasVapor') {
              arr.push({ label: element.name, value: element.id})
            }
          })
        }
      }
      arr.push({ label: 'Другое вещество', value: 'newSubstance' })
      return arr
    },
    equipmentClass() {
      return this.form.equipmentClass
    },
    stateOfAggregation() {
      return this.form.stateOfAggregation
    },
    substance() {
      return this.form.substance
    },

    selectedSubstance() {
      if (this.substance === 'gasoline') {
        return {
          name: 'Бензин',
          carbonNumber: 6.911,
          hydrogenNumber: 12.168,
          oxygenNumber: 0,
          halogenNumber: 0,
          molarMass: 95,
          specificHeatOfCombustion: 44094000,
          bottomConcentrationLimit: 1,
          fuelClass: 'class_3',
          massBurningRate: 0.06,
          substanceDensity: 729,
          constA: 4.26511,
          constB: 695.019,
          constCa: 223.22,
          flashPoint: 236,
          adiabaticExponent: undefined,
          boilingTemperature: 388,
          specificHeat: undefined,
          specificHeatOfVaporization: undefined,
          criticalPressure: undefined,
          criticalTemperature: undefined,
          classOfSubstanceJetFire: 'liquidPhaseLiquefiedGasOrLiquid',
          nameOfSubstancePoolFire: 'gasoline'
        }
      } else if (this.substance === 'methaneCompressed') {
        return {
          name: 'Метан сжатый',
          carbonNumber: 1,
          hydrogenNumber: 4,
          oxygenNumber: 0,
          halogenNumber: 0,
          molarMass: 16,
          specificHeatOfCombustion: 50000000,
          bottomConcentrationLimit: 5.28,
          fuelClass: 'class_4',
          massBurningRate: undefined,
          substanceDensity: undefined,
          constA: undefined,
          constB: undefined,
          constCa: undefined,
          flashPoint: undefined,
          adiabaticExponent: 1.42,
          boilingTemperature: undefined,
          specificHeat: undefined,
          specificHeatOfVaporization: undefined,
          criticalPressure: undefined,
          criticalTemperature: undefined,
          classOfSubstanceJetFire: 'compressedGas',
          nameOfSubstancePoolFire: undefined
        }
      } else if (this.substance === 'propaneLiquefied') {
        return {
          name: 'Пропан сжиженный',
          carbonNumber: 3,
          hydrogenNumber: 8,
          oxygenNumber: 0,
          halogenNumber: 0,
          molarMass: 44,
          specificHeatOfCombustion: 46353000,
          bottomConcentrationLimit: 2.3,
          fuelClass: 'class_2',
          massBurningRate: 0.1,
          substanceDensity: 509,
          constA: 5.95547,
          constB: 813.864,
          constCa: 248.116,
          flashPoint: undefined,
          adiabaticExponent: undefined,
          boilingTemperature: 231,
          specificHeat: 2230,
          specificHeatOfVaporization: 425700,
          criticalPressure: 4.27e+6,
          criticalTemperature: 370,
          classOfSubstanceJetFire: 'liquidPhaseLiquefiedGasOrLiquid',
          nameOfSubstancePoolFire: 'LPG'
        }
      } else {
        let substances = []
        if(this.$store.state.Project.currentProject.substances) {
          substances = this.$store.state.Project.currentProject.substances
        }

        if(this.materials) {
          substances = [...substances, ... this.materials]
        }
        
        let chosenSubstance = substances.find(el => el.id === this.substance)
        let substance = chosenSubstance.settings
        substance.name = chosenSubstance.name
        substance.fuelClass = chosenSubstance.fuelClass || chosenSubstance.class
        return substance
      }
    },
  },
  watch: {
    equipmentClass() {
      this.form.stateOfAggregation = this.statesOfAggregation[0].value
      this.form.substance = this.substances[0].value
    },
    stateOfAggregation() {
      this.form.substance = this.substances[0].value
    },
    currentProject: {
      deep: true,
      handler() {
        if(!this.saveEditMode) {
          this.form.substance = this.substances[this.substances.length - 2].value
        }
      },
    },
  },
  methods: {
    getSubstances() {
      this.$store.dispatch('Substances/getSubstances')
        .then(resp => {
          this.materials = resp.data.data
        })
        .catch(err => {
          console.log(err.response.data.errors)
        })
    },
    nextStep() {
      if (this.currentStep < 3) {
        if (this.currentStep === 1) this.$store.commit('Project/setData', { label: 'substance', data: this.selectedSubstance })
        
        if(this.currentStep === 2){
          var errorStatus = false
          let fields = document.querySelectorAll('.el-input__inner')

          for(let i=0; i < fields.length; i++ ) {
            if(!fields[i].value) {
              this.errorNotify('Заполните обязательные поля')
              errorStatus = true
              break
            } 
          }

          if(errorStatus) {
            return false
          }
        }
        this.currentStep += 1
      } else {
        const accidents = JSON.parse(JSON.stringify(this.$store.state.Project.accidents))
        let error = null
        if(accidents) {
          accidents.map((element, index) => {
            if(!element.trees.length) {
              error = `Необходимо создать дерево для аварии ${index + 1}`
            } else{
              element.trees.map((treeElement) => {
                if(!treeElement.frequencyScenario) {
                  error = `Необходимо рассчитать деревья для аварии ${index + 1}`
                } else if(treeElement.frequencyScenario.length){
                  treeElement.frequencyScenario.forEach(element => {
                    if(!element || element === null || element === undefined) {
                      error = `Необходимо рассчитать деревья для аварии ${index + 1}`
                    }
                  });
                }
              })
            }
          })
        }
        
        if(!error) {
          this.saveEditMode = true
          if(!this.editData) {
            this.saveEquipment()
          } else {
            this.editEquipment()
          }
        } else {
          this.errorNotify(error)
        }
      }
    },
    returnSurface() {
      if(this.form.surfaceProperty === "concrete") {
        this.form.surfaceSpreadCoefficient = 150
        this.form.surfaceThermalConductivity = 1.5
        this.form.surfaceSpecificHeat = 1000
        this.form.surfaceDensity = 2200
      } else if(this.form.surfaceProperty === "plannedGround") {
        this.form.surfaceSpreadCoefficient = 20
        this.form.surfaceThermalConductivity = 1.67
        this.form.surfaceSpecificHeat = 2150
        this.form.surfaceDensity = 2000
      } else if(this.form.surfaceProperty === "unplannedGround") {
        this.form.surfaceSpreadCoefficient = 5
        this.form.surfaceThermalConductivity = 1.67
        this.form.surfaceSpecificHeat = 2150
        this.form.surfaceDensity = 2000
      }
    },
    saveEquipment() {
      const arrPointEquipment = JSON.parse(JSON.stringify(this.$store.state.Project.currentMachinesPosition))
      const firesObj = []
      this.$store.state.Accidents.failureParameterObj.map((element) => {
        let firesArr = []
        if(element.trees) {
          element.trees.map((element) => {
            element.fires.map((element) => {
              firesArr.push(element.label.toLowerCase())
            })
          })
        }
        
        firesObj.push(firesArr)
      })
      const frequencyScenarioObj = []
      this.$store.state.Accidents.failureParameterObj.map((element) => {
        let frequencyArr = []
        if(element.trees) {
          element.trees.map((element) => {
            element.frequencyScenario.map((element) => {
              frequencyArr.push(element)
            })
          })
        }
        frequencyScenarioObj.push(frequencyArr)
      })
      for (let n = 0; n < arrPointEquipment.length; n++) {
        this.$store.commit('Project/addNewEquipment', {
          nameOfEquipment: this.form.name,
          descriptionOfEquipment: this.form.description,
          x: arrPointEquipment[n].x,
          y: arrPointEquipment[n].y,
          substance: this.$store.state.Project.substance,
          equipmentLocation: this.form.equipmentLocation,
          explosionInOpenSpace: this.form.explosionInOpenSpace,
          valvePressure: this.form.valvePressure,
          failureParameterObj: this.$store.state.Accidents.failureParameterObj,
          frequencyScenarioObj,
          firesObj,
          pointId: arrPointEquipment[n].pointId,
          groupId: this.currentGroupId,

          objDetailData: {
            id: Date.now() + n,
            baseData: this.form,
            accidents: this.$store.state.Project.accidents,
          }
        })
      }
      this.$store.commit('Project/addDrawPoints', { data:arrPointEquipment, catName: 'Equipment' })
      this.$store.commit('Project/setData', { label: 'currentMachinesPosition', data: null })
      this.$store.commit('Accidents/setData', { label: 'failureParameterObj', data: [] })
      this.$store.commit('Project/setData', { label: 'selectedSwitcherName', data: 'Аппараты' })
      this.$store.commit('Project/setData', { label: 'selectedSwitcher', data: !this.$store.state.Project.selectedSwitcher })
      this.$store.dispatch('Dialog/closeDialog')
      this.$store.commit('Project/setData', { label: 'currentGroupId', data: Date.now() })
    },

    editEquipment() {
      const firesObj = []
      this.$store.state.Accidents.failureParameterObj.map((element) => {
        let firesaArr = []
        if(element.trees) {
          element.trees.map((element) => {
            element.fires.map((element) => {
              firesaArr.push(element.label.toLowerCase())
            })
          })
        }
        firesObj.push(firesaArr)
      })
      const frequencyScenarioObj = []
      this.$store.state.Accidents.failureParameterObj.map((element) => {
        let frequencyArr = []
        if(element.trees) {
          element.trees.map((element) => {
            element.frequencyScenario.map((element) => {
              frequencyArr.push(element)
            })
          }) 
        }
        frequencyScenarioObj.push(frequencyArr)
      })
      this.$store.commit('Project/editEquipment', {
        nameOfEquipment: this.form.name,
        descriptionOfEquipment: this.form.description,
        substance: this.$store.state.Project.substance,
        equipmentLocation: this.form.equipmentLocation,
        explosionInOpenSpace: this.form.explosionInOpenSpace,
        valvePressure: this.form.valvePressure,
        failureParameterObj: this.$store.state.Accidents.failureParameterObj,
        frequencyScenarioObj,
        firesObj,
        groupId: this.groupId,

        objDetailData: {
          id: this.id,
          baseData: this.form,
          accidents: this.$store.state.Project.accidents,
        }
      })
      this.$store.commit('Project/setData', { label: 'currentEditObject', data: null })
      this.$store.commit('Accidents/setData', { label: 'failureParameterObj', data: [] })
      this.$store.dispatch('Dialog/closeDialog')
    },

    cancel() {
      this.$store.commit('Project/setData', { label: 'currentEditObject', data: null })
      this.$store.commit('Accidents/setData', { label: 'failureParameterObj', data: [] })
      this.$store.dispatch('Dialog/closeDialog')
      this.$store.commit('Dialog/setData', { label: 'redrawCanvasAfterCloseDialog', data: true })
    },
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/helpers/variables';
@import '../../assets/styles/helpers/mixin';

.equipment {
  padding: 3.4rem 0 6rem;

  &__title {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3.3rem;
    color: $lightBlack;
    text-align: center;

    &--mg {
      margin-top: 2rem;
    }
  }

  &__steps {
    margin-top: 2.6rem;
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;

    span {
      display: inline-block;
      background: $grey;
      border-radius: 6px;
      width: 60px;
      height: 4px;
      margin-right: 2rem;

      &.current {
        background: $blue;
      }

      &.previous {
        background: $basicBlack;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $lightGrey;
    padding: 2rem 3rem;
    
    &.flex-start {
      justify-content: flex-start;
    }
    
    &-label {
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $basicBlack;
      width: calc(100% - 24rem);
      padding-right: 2rem;
      word-break: normal;
    }

    &-input {
      width: 40rem;
    }

    .el-checkbox {
      flex-direction: row-reverse;

      &__label {
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: $basicBlack;
        padding-left: 0;
        padding-right: 2rem;
        word-break: normal;
      }
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: $basicBlack;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
  }
  .tree-notification{
    color: $blue;
    text-align: center;
    padding: 0 10px;
    font-size: 13px;
    margin-top: 2rem;
  }

  .btn-container {
    margin-left: 2rem;
  }
}
</style>
