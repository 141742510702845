const state = () => ({
  dialogVisible: false,
  dialogNotificationVisible: false,
  activeDialog: '',
  titleDialog: '',
  confirmAnswer: '',
  redrawCanvasAfterCloseDialog: false,
  showClose: true,
})

const getters = {}

const mutations = {
  setData(state, data) {
    state[data.label] = data.data
  },
}

const actions = {
  closeDialog({ commit }) {
    commit('setData', { label: 'dialogVisible', data: false })
    commit('setData', { label: 'dialogNotificationVisible', data: false })
    setTimeout(() => {
      commit('setData', { label: 'activeDialog', data: '' })
      commit('setData', { label: 'titleDialog', data: '' })
    }, 200)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
