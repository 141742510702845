<template>
  <div class="admin">
    <div class="admin__header">
      <router-link to="/" class="admin__header-logo">
        <img src="../assets/images/logo-admin.svg" alt="">
      </router-link>
      <div class="admin__header-nav">
        <router-link class="admin__header-link" :to="{ name: 'Users' }">
          Пользователи
        </router-link>
        <router-link class="admin__header-link" :to="{ name: 'Cities' }">
          Города
        </router-link>
        <router-link class="admin__header-link" :to="{ name: 'Substances' }">
          Вещества
        </router-link>
      </div>
      <div class="admin__header-logout" @click="logout">
        Выйти
        <img src="../assets/icons/arrow-right-white.svg" alt="">
      </div>
    </div>
    <div class="admin__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminLayout',
  methods:{
    logout(){
      this.$store.dispatch('Auth/logout')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.admin {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $lightBlack;
    height: 9rem;
    padding: 0 3rem;

    &-logo {
      width: 23rem;

      &>img {
        width: 100%;
      }
    }

    &-nav {
      height: 100%;
      display: flex;
    }

    &-link {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $lightGrey;
      margin-right: 6rem;
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
      transition: 0.3s ease-out;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: transparent;
        transition: 0.3s ease-out;
      }

      &.router-link-active,
      &:hover {
        color: $orange;

        &:after {
          background: $orange;
        }
      }
    }

    &-logout {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 1.4rem;
      color: $lightGrey;
      cursor: pointer;

      &>img {
        margin-left: 1rem;
        display: block;
        transition: .5s;
      }

      &:hover {
        &>img {
          transform: translateX(4px);
        }
      }
    }
  }
}
</style>
