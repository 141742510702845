import axios from 'axios'
const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
  token: localStorage.getItem('token') || '',
  user : localStorage.getItem('user') || '',
  role: localStorage.getItem('role') || null,
  resetPasswordEmail: null,
})
const getters = {}
const mutations = {
  setData(state, data) {
    state[data.label] = data.data
  },
  auth_success (state, data) {
    state.status = 'success'
    state.token = data.token
    state.role = data.role
    state.user = data.user
    localStorage.setItem('token', data.token)
    localStorage.setItem('role', data.role)
    localStorage.setItem('user', data.user)
  },
  logout (state) {
    state.status = ''
    state.token = ''
    state.user = null
    state.errors = null
    state.resetPasswordEmail = null
    state.activeAccount = null
  },
}
const actions = {
  login ({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/auth/login', data: user, method: 'POST' })
        .then(resp => {
          const token = resp.data.data.token
          const role = resp.data.data.role
          const user = resp.data.data.id
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.data.token
          commit('auth_success', {token, role, user})
          commit('setData', {label:"user", data: user})
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  registration (ctx, user) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/auth/signup', data: user, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendEmail (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/auth/forgot-password', data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  resetPass (ctx, data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/auth/reset-password', data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('role')
      localStorage.removeItem('user')
      delete axios.defaults.headers.common['Authorization']
      document.location.href = '/login'
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
