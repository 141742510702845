import axios from 'axios'
const apiUrl = process.env.VUE_APP_API_URL

const state = () => ({
  users: null,
  usersStatistic: null,
  user: null,
})
const getters = {}
const mutations = {
  setData(state, data) {
    state[data.label] = data.data
  },
}
const actions = {
  getUsers ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/admin/users', method: 'GET', params: params  })
        .then(resp => {
          commit('setData', { label: 'users', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUsersStatistic ({ commit }) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/admin/users/statistics', method: 'GET' })
        .then(resp => {
          commit('setData', { label: 'usersStatistic', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addUser (ctx , data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + '/admin/user', data: data.data, method: 'POST' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUser ({ commit } , id) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/admin/user/${id}`, method: 'GET' })
        .then(resp => {
          commit('setData', { label: 'user', data: resp.data.data })
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateUser (ctx , data) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/admin/user/${data.id}`, data: data.data , method: 'PUT' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  delUser (ctx , id) {
    return new Promise((resolve, reject) => {
      axios({ url: apiUrl + `/admin/user/${id}`, method: 'DELETE' })
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
