import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      layout: 'AdminLayout',
      authRequired: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      layout: 'AuthLayout',
      onlyWhenLoggedOut: true,
    }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('../views/Registration.vue'),
    meta: {
      layout: 'AuthLayout',
      onlyWhenLoggedOut: true,
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPass',
    component: () => import('../views/ForgotPass.vue'),
    meta: {
      layout: 'AuthLayout',
      onlyWhenLoggedOut: true,
    }
  },
  {
    path: '/reset-password',
    name: 'ChangePass',
    component: () => import('../views/ChangePass.vue'),
    meta: {
      layout: 'AuthLayout',
      onlyWhenLoggedOut: true,
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue'),
    meta: {
      layout: 'AdminLayout',
      authRequired: true,
    }
  },
  {
    path: '/users/:id',
    name: 'User',
    component: () => import('../views/User.vue'),
    meta: {
      layout: 'AdminLayout',
      authRequired: true,
    }
  },
  {
    path: '/cities',
    name: 'Cities',
    component: () => import('../views/Cities.vue'),
    meta: {
      layout: 'AdminLayout',
      authRequired: true,
    }
  },
  {
    path: '/cities/:id',
    name: 'City',
    component: () => import('../views/City.vue'),
    meta: {
      layout: 'AdminLayout',
      authRequired: true,
    }
  },
  {
    path: '/substances',
    name: 'Substances',
    component: () => import('../views/Substances.vue'),
    meta: {
      layout: 'AdminLayout',
      authRequired: true,
    }
  },
  {
    path: '/substances/:id',
    name: 'Substance',
    component: () => import('../views/Substance.vue'),
    meta: {
      layout: 'AdminLayout',
      authRequired: true,
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    meta: {
      layout: 'UserLayout',
      authRequired: true,
    }
  },
  {
    path: '/tariffs',
    name: 'Tariffs',
    component: () => import('../views/Tariffs.vue'),
    meta: {
      layout: 'UserLayout',
      authRequired: true,
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/Projects.vue'),
    meta: {
      layout: 'UserLayout',
      public: true,
      authRequired: true,
    }
  },
  {
    path: '/projects/:id',
    name: 'Project',
    component: () => import('../views/Project.vue'),
    meta: {
      layout: 'UserLayout',
      authRequired: true,
    }
  },
  {
    path: '/calculate',
    name: 'Calculate',
    component: () => import('../views/Calculate.vue'),
    meta: {
      layout: 'UserLayout',
      authRequired: true,
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('token')
  const RequireAuth = to.matched.some(record => record.meta.authRequired)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);
  const layout = to.meta.layout;
  const role = localStorage.getItem('role')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + loggedIn
  if(to.query.tarif && !loggedIn) {
    localStorage.setItem('tarifLink', to.fullPath)
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next('/projects')
  }

  if (layout === 'AdminLayout' && role && role !== 'admin') {
    return next('/projects')
  }

  if (loggedIn) {
    return next()
  }else if(RequireAuth){
    return next('/login')
  }

  next()
});

export default router;
