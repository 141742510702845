<template>
  <div class="profile">
    <div v-if="user" class="profile__header">
      <div class="profile__header--right">
        <router-link to="/" class="profile__header-logo">
          <img src="../assets/images/logo.svg" alt="">
        </router-link>
        <router-link v-if="isShowedLink" :to="{name: 'Projects'}" class="profile__header-back">
          <img src="../assets/icons/icon-arrow-left-white.svg" alt="">
          Назад к проектам
        </router-link>
      </div>
      <div class="profile__header--left">
        <ButtonElement v-if="!isShowedLink || getDemoDays(user.role_expiration_date) >= '1'" text="Создать проект" fill="empty" color="white" @click="createNewProject" />
        <div class="profile__header-menu">
          <div
            class="profile__header-menu-button"
            :class="{open: isShowedMenu}"
            @click="isShowedMenu = !isShowedMenu"
          >
            Мой профиль
            <img src="../assets/icons/icon-arrow-down.svg" alt="">
          </div>
          <div v-show="isShowedMenu" class="profile__header-menu-content">
            <router-link class="profile__header-menu-item" to="/account">
              Редактировать
            </router-link>
            <div class="profile__header-menu-item" @click="logout">
              Выйти
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="user && user.role === 'demo'" class="profile__subscribe">
      <div v-if="getDemoDays(user.role_expiration_date) >= 1" class="profile__subscribe-text">
        Вы используете демо-версию продукта. Осталось {{ getDemoDays(user.role_expiration_date) }} дней.
      </div>
      <div v-else class="profile__subscribe-text">
        Время работы демо-версии продукта закончилось
      </div>
      <router-link to="/tariffs" class="profile__subscribe-link">
        Купить полную версию
      </router-link>
    </div>
    <div v-if="licenseMessage || licenseHasExpired" class="profile__subscribe">
      <div v-if="licenseExpires " class="profile__subscribe-text">
        Срок действия вашей лицензии заканчивается через {{ getDemoDays(user.role_expiration_date) }} дней.
      </div>
      <div v-else-if="licenseHasExpired" class="profile__subscribe-text">
        Срок действия вашей лицензии закончился
      </div>
      <router-link to="/tariffs" class="profile__subscribe-link">
        Продлить лицензию
      </router-link> 

      <div v-if="licenseExpires" class="profile__subscribe-close" @click="closelicenseMessage">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L13 13" stroke="#8C959C"/>
          <path d="M13 1L0.999999 13" stroke="#8C959C"/>
        </svg>
      </div>
    </div>
    <div class="profile__content" :class="{'profile__content--padding' : user && (user.role === 'demo' || licenseHasExpired)}">
      <slot />
    </div>
  </div>
</template>

<script>
import ButtonElement from '../components/elements/ButtonElement'
import { DateParser } from '@/mixin/date_parser'
import { GetCookie } from '@/mixin/get_cookie'
import { ElMessage } from 'element-plus'

export default {
  name: 'UserLayout',
  mixins: [DateParser, GetCookie],
  components: {
    ButtonElement,
  },
  data() {
    return {
      isShowedMenu: false,
      isShowedLink: false,
      isTrial: true,
      licenseMessage: false,
    }
  },
  mounted() {
    this.isShowedLink = this.$route.name !== 'Projects'
    if(!this.user){
      this.$store.dispatch('Personal/getUser')
        .then(() => {})
        .catch(err => {
          console.log(err.response.data.errors)
        })
    } else if (!this.getCookie('licenseMessageHidden') && this.licenseExpires) {
      this.licenseMessage = true
    }
    if(!this.projectsList) {
      this.$store.dispatch('Project/getProjects', this.$route.query)
    }
  },
  computed: {
    user(){
      return this.$store.state.Personal.user
    },
    projectsList(){
      return this.$store.state.Project.projectsList
    },
    licenseExpires() {
      if (this.user && this.user.role !== 'demo' && this.user.role !== 'admin' && this.user.role_expiration_date && this.getDemoDays(this.user.role_expiration_date) <= 7 && this.getDemoDays(this.user.role_expiration_date) >= 1) {
        return true
      }
      return false
    },
    licenseHasExpired() {
      if (this.user && this.user.role !== 'demo' && this.user.role !== 'admin' && (!this.user.role_expiration_date || this.getDemoDays(this.user.role_expiration_date) < 1)) {
        return true
      }
      return false
    },
  },
  watch: {
    $route(to) {
      this.isShowedMenu = false
      this.isShowedLink = to.name !== 'Projects'
    },
    user() {
      if (!this.getCookie('licenseMessageHidden') && this.licenseExpires) {
        this.licenseMessage = true
      }
    },
  },
  methods: {
    createNewProject() {
      if(this.user.role !== 'demo' || (this.user.role === 'demo' &&  this.projectsList.length < 1) ) {
        this.$store.commit('Dialog/setData', { label: 'titleDialog', data: 'Новый проект' })
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'NewProject' })
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      } else {
        ElMessage({
          type: 'error',
          message: 'В демо доступе можно создать только один проект',
        })
      }
    },
    closelicenseMessage() {
      this.licenseMessage = false
      document.cookie = `licenseMessageHidden=true;max-age=86400`;
    },
    logout(){
      this.$store.dispatch('Auth/logout')
    },
  }
}
</script>

<style lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.profile {
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $lightBlack;
    height: 9rem;
    padding-left: 3rem;
    z-index: 600;
    position: relative;

    &--right,
    &--left {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &-back {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $lightGrey;

      &>img {
        margin-right: 1rem;
      }
    }

    &-logo {
      width: 13rem;
      margin-right: 13rem;
      display: block;

      &>img {
        width: 100%;
      }
    }

    &-menu {
      margin-left: 15rem;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $lightGrey;
      position: relative;
      height: 100%;

      &-button {
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;
        padding: 0 3rem;

        &.open {
          &>img {
            transform: rotate(-180deg);
          }
        }

        &>img {
          margin-left: 1rem;
          transition: 0.3s ease-out;
        }
      }

      &-content {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: $lightBlack;
        z-index: 1;
      }

      &-item {
        padding: 1.8rem 3rem;
        box-shadow: inset 0 1px 0 #545454;
        display: block;
        cursor: pointer;
        transition: 0.3s ease-out;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &__subscribe {
    background: #ffa800;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: $basicWhite;
    position: absolute;
    left: 0;
    top: 9rem;
    width: 100%;
    z-index: 500;

    &-link {
      display: block;
      color: $lightBlack;
      background: $basicWhite;
      border-radius: 4px;
      font-size: 1.2rem;
      height: 3.2rem;
      line-height: 3.2rem;
      padding: 0 0.9rem;
      margin-left: 1.6rem;
      transition: 0.3s ease-out;

      &:hover {
        background: $lightGrey;
      }
    }

    &-close {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }

      path {
        stroke: #fff;
        stroke-width: .3rem;
      }
    }
  }

  &__content {
    min-height: calc(100vh - 9rem);
    display: flex;

    &--padding {
      padding-top: 6rem;
    }
  }
}
</style>
