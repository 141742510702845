import { createStore } from 'vuex'

import Dialog from './modules/dialog'
import Project from './modules/project'
import Accidents from './modules/accidents'
import Auth from './modules/auth'
import Personal from './modules/personal'
import Products from './modules/products'
import Cities from './modules/cities'
import Substances from './modules/substances'
import Users from './modules/users'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Dialog,
    Project,
    Accidents,
    Auth,
    Personal,
    Products,
    Cities,
    Substances,
    Users
  },
});
