<template>
  <el-form
    :model="form"
    ref="substanceForm"
    :show-message="false"
    :rules="rules"
  >
    <div class="new-substance">
      <div class="new-substance__row">
        <div class="new-substance__label">
          Название вещества
        </div>
        <el-form-item
          prop="name"
        >
          <div class="new-substance__input">
            <el-input
              v-model="form.name"
              @focus="isActiveInput = 'name'"
              @blur="isActiveInput = ''"
            />
          </div>
        </el-form-item>
      </div>
      <div  v-show="!typeSubstance" class="new-substance__row">
        <div class="new-substance__label">
          Агрегатное состояние вещества
        </div>
        <div class="new-substance__input">
          <el-select v-model="form.stateOfAggregation">
            <el-option label="Жидкость" value="liquid" />
            <el-option label="Сжатый газ" value="compressedGas" />
            <el-option label="Жидкая фаза сжиженного газа" value="liquefiedGasFluid" />
            <el-option label="Паровая фаза сжиженного газа" value="liquefiedGasVapor" />
          </el-select>
        </div>
      </div>

      <div class="new-substance__row">
        <div class="new-substance__label">
          Число атомов углерода в молекуле горючего:
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.carbonNumber"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div class="new-substance__row">
        <div class="new-substance__label">
          Число атомов водорода в молекуле горючего:
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.hydrogenNumber"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div class="new-substance__row">
        <div class="new-substance__label">
          Число атомов кислорода в молекуле горючего:
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.oxygenNumber"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div class="new-substance__row">
        <div class="new-substance__label">
          Число атомов галоидов в молекуле горючего:
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.halogenNumber"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div class="new-substance__row">
        <div class="new-substance__label">
          Молярная масса, кг/кмоль:
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.molarMass"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div class="new-substance__row">
        <div class="new-substance__label">
          Удельная теплота сгорания горючего газа или пара, Дж/кг:
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.specificHeatOfCombustion"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div class="new-substance__row">
        <div class="new-substance__label">
          Нижний концентрационный предел распространения пламени, % (об.):
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.bottomConcentrationLimit"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div class="new-substance__row">
        <div class="new-substance__label">
          Класс горючего вещества по степени чувствительности к воздуждению взрывных процессов:
        </div>
        <div class="new-substance__input">
          <el-select v-model="form.fuelClass">
            <el-option label="Класс 1" value="class_1" />
            <el-option label="Класс 2" value="class_2" />
            <el-option label="Класс 3" value="class_3" />
            <el-option label="Класс 4" value="class_4" />
          </el-select>
        </div>
      </div>

      <div v-if="form.stateOfAggregation !== 'compressedGas'" class="new-substance__row">
        <div class="new-substance__label">
          Удельная массовая скорость выгорания жидкости, кг/(м2*с):
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.massBurningRate"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div v-if="form.stateOfAggregation !== 'compressedGas'" class="new-substance__row">
        <div class="new-substance__label">
          Плотность вещества, кг/м3:
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.substanceDensity"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <p class="new-substance__form-text" v-if="form.stateOfAggregation !== 'compressedGas'">
        Константы уравнения Антуана:
      </p>
      <div v-if="form.stateOfAggregation !== 'compressedGas'" class="new-substance__row">
        <div class="new-substance__label">
          A
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.constA"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div v-if="form.stateOfAggregation !== 'compressedGas'" class="new-substance__row">
        <div class="new-substance__label">
          B
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.constB"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div v-if="form.stateOfAggregation !== 'compressedGas'" class="new-substance__row">
        <div class="new-substance__label">
          Ca
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.constCa"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>

      <div v-if="form.stateOfAggregation === 'liquid' || form.stateOfAggregation === 'liquefiedGasFluid' || form.stateOfAggregation === 'liquefiedGasVapor'" class="new-substance__row">
        <div class="new-substance__label">
          Температура кипения при нормальных условиях, К:
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.boilingTemperature"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div v-if="form.stateOfAggregation === 'liquefiedGasFluid' || form.stateOfAggregation === 'liquefiedGasVapor'" class="new-substance__row">
        <div class="new-substance__label">
          Удельная теплоемкость сжиженного газа, Дж/(кг*К):
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.specificHeat"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div v-if="form.stateOfAggregation === 'liquefiedGasFluid' || form.stateOfAggregation === 'liquefiedGasVapor'" class="new-substance__row">
        <div class="new-substance__label">
          Удельная теплота парообразования, Дж/кг:
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.specificHeatOfVaporization"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div v-if="form.stateOfAggregation === 'liquefiedGasFluid' || form.stateOfAggregation === 'liquefiedGasVapor'" class="new-substance__row">
        <div class="new-substance__label">
          Критическое давление сжиженного газа, Па:
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.criticalPressure"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div v-if="form.stateOfAggregation === 'liquefiedGasFluid' || form.stateOfAggregation === 'liquefiedGasVapor'" class="new-substance__row">
        <div class="new-substance__label">
          Критическая температура сжиженного газа, К:
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.criticalTemperature"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div v-if="form.stateOfAggregation === 'liquid'" class="new-substance__row">
        <div class="new-substance__label">
          Температура вспышки, К:
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.flashPoint"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div v-if="form.stateOfAggregation === 'compressedGas'" class="new-substance__row">
        <div class="new-substance__label">
          Показатель адиабаты:
        </div>
        <div class="new-substance__input">
          <el-input-number
            v-model="form.adiabaticExponent"
            controls-position="right"
            :min="0"
          />
        </div>
      </div>
      <div class="new-substance__row">
        <div class="new-substance__label">
          Класс вещества в оборудовании (для расчета факела):
        </div>
        <div class="new-substance__input">
          <el-select v-model="form.classOfSubstanceJetFire">
            <el-option label="Сжатый газ" value="compressedGas" />
            <el-option label="Паровая фаза сжиженного газа" value="vaporPhaseLiquefiedGas" />
            <el-option label="Жидкая фаза сжиженного газа или жидкость" value="liquidPhaseLiquefiedGasOrLiquid" />
          </el-select>
        </div>
      </div>

      
      <div v-if="form.stateOfAggregation !== 'compressedGas'" class="new-substance__row">
        <div class="new-substance__label">
          Тип жидкости в оборудовании (для расчета пожара пролива):
        </div>
        <div class="new-substance__input">
          <el-select v-model="form.nameOfSubstancePoolFire">
            <el-option label="Сжиженный природный газ" value="LNG" />
            <el-option label="Сжиженный углеводородный газ" value="LPG" />
            <el-option label="Бензин" value="gasoline" />
            <el-option label="Дизельное топливо" value="diesel" />
            <el-option label="Нефть и другие нефтепродукты" value="oilOrOilProduct" />
            <el-option label="Однокомпонентная жидкость" value="oneComponentLiquid" />
          </el-select>
        </div>
      </div>
      <div class="new-substance__actions">
        <ButtonElement 
        :text="this.$route.params.id === 'new' ? 'Создать' : 'Сохранить'" 
        @click.prevent="substanceAction('substanceForm', this.saveInProject)" />

        <el-popconfirm 
          v-if="this.$route.params.id !== 'new' &&  !saveInProject" 
          title="Удалить вещество ?" 
          cancelButtonText="Отмена"
          confirmButtonText="Да"
          @confirm="dellSubstance"
        >
          <template #reference>
            <ButtonElement
              text="Удалить"
              @click.prevent
            />
          </template>
        </el-popconfirm>
      </div>
    </div>
  </el-form>
</template>

<script>
import ButtonElement from '../components/elements/ButtonElement'
import { AlertNotify } from '@/mixin/alert_notify'

export default {
  name: 'AddNewSubstance',
  mixins: [AlertNotify],
  components: {
    ButtonElement,
  },
  props: {
    typeSubstance: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      saveInProject: false,
      form: {
        name: '',
        carbonNumber: '',
        hydrogenNumber: '',
        oxygenNumber: '',
        halogenNumber: '',
        molarMass: '',
        specificHeatOfCombustion: '',
        bottomConcentrationLimit: '',
        fuelClass: 'class_1',
        massBurningRate: '',
        substanceDensity: '',
        constA: '',
        constB: '',
        constCa: '',
        flashPoint: '',
        adiabaticExponent: '',
        boilingTemperature: '',
        specificHeat: '',
        specificHeatOfVaporization: '',
        criticalPressure: '',
        criticalTemperature: '',
        classOfSubstanceJetFire: 'compressedGas',
        nameOfSubstancePoolFire: 'LNG',
        stateOfAggregation: 'liquid'
      },
      rules: {
        name: [
          { required: true, trigger: 'blur' },
        ],
      }
    }
  },
  mounted() {
    if(this.typeSubstance) {
      this.form.stateOfAggregation = this.typeSubstance
    }
    
    if(this.$route.params.id !== 'new' && !this.$store.state.Project.currentProject) {
      this.$store.dispatch('Substances/getSubstance', this.$route.params.id)
        .then(res => {
          this.form.name = res.data.data.name
          this.form.fuelClass = res.data.data.class
          for(let key in res.data.data.settings) {
            this.form[key] = res.data.data.settings[key]
          }
        })
        .catch(err => {
          console.log(err.response.data.errors)
        })
    }
    if(this.$store.state.Project.currentProject){
      this.saveInProject = true
    }
  },
  methods: {
    substanceAction(form, saveInProject) {
      this.$refs[form].validate(valid => {
        if(valid) {
          const data = { id: Date.now(), name: this.form.name, class: this.form.fuelClass, state_of_aggr: this.form.stateOfAggregation, settings: {} }
          for(let key in this.form) {
            if(key !== 'name' && key !== 'fuelClass') {
              data.settings[key] = this.form[key]
            }
          }
          if(!saveInProject) {
            this.$store.dispatch(this.$route.params.id === 'new' ? 'Substances/addAdminSubstance' : 'Substances/changeAdminSubstance', { id: this.$route.params.id, data })
              .then(() => {
                if(this.$route.params.id){
                  this.successNotify('Данные обновлены')
                }
                this.$router.push('/substances')
              })
              .catch(err => {
                console.log(err.response.data.errors)
              })
          } else{
            this.$store.commit('Project/addSubstancePoints', data)
          }
        }
      })
    },
    dellSubstance() {
      this.$store.dispatch('Substances/dellSubstance', this.$route.params.id)
        .then(() => {
            this.$router.push('/substances')
          })
          .catch(err => {
            console.log(err.response.data.errors)
          })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.new-substance {
  &__form-text{
    font-size: 1.8rem;
    line-height: 1.9rem;
    color: #000;
    padding: 2rem 3rem 0;
    text-align: center;
    font-weight: 600;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eff2f6;
    padding: 2rem 3rem;
  }

  &__input {
    width: 24rem;
  }

  &__label {
    width: calc(100% - 26rem);
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #000;
    word-break: normal;
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
}
</style>
